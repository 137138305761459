import FileAttach from "../Data/FileAttach";
import React, {useEffect, useRef} from "react";


type FileInputProps = {
    name: string,
    file: FileAttach
}
const FileInput = ({name, file}: FileInputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (inputRef.current != null && file.file != null) {
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(file.file);
            inputRef.current.files = dataTransfer.files;
        }
    }, []);

    return file.file ? <input type="file" ref={inputRef} name={name === null ? "FormAttachFile[]" : name} style={{display: "none"}}/> : <></>;
}

export default FileInput;