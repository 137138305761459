export const ID = "account";

/* VCD: CASH DISBURSEMENT VOUCHER */
export const MENU_VCD = "vcd";
export const MENU_VCD_COMMON = "common";
export const MENU_VCD_ACCOUNT = "account";
export const MENU_VCD_CARD = "card";
export const MENU_VCD_TAXBILL = "taxbill";

export const MENU_MAT = "mat";
export const MENU_MAT_REQUEST = "request";
export const MENU_MAT_ORDER = "order";
