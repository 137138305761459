import React, {CSSProperties, forwardRef} from "react";
import {FileViewerDialog, FileViewerType} from "common/control/FileViewerControl";
import {useTheme} from "context/ThemeContext";
import {humanFileSize, isEmptyString} from "@hskernel/hs-utils";
import {HEADER_AUTH, sendRNData} from "Define";
import {IconCircleXFilled, IconFileDownload, IconFileSearch} from "@tabler/icons-react";
import {isWVMode} from "lib/RNMessage/RNMessageWV";
import RNDataDownload from "lib/RNMessage/Data/RNDataDownload";
import {Link} from "react-router-dom";
import styles from "../index.module.css";
import {COLOR_LINK_DEFAULT} from "DefineStyle";
import {FileKind} from "../index";
import FileAttach from "../Data/FileAttach";
import Thumb from "./Thumb";
import FileInput from "./FileInput";

const thumbBoxStyle: CSSProperties = {
    width: 100,
    display: "block",
    marginRight: 8,
    marginBottom: 5,
    float: "left"
}

const thumbStyle: CSSProperties = {
    boxSizing: "border-box",
    width: 100,
    display: "inline-flex",
    height: 100,
    marginRight: 8,
};

const thumbInnerStyle: CSSProperties = {
    display: "flex",
    width: "100%",
    overflow: "hidden",
    border: "1px solid rgb(233 233 233)",
    position: "relative"
};

type FileAttachItemProps = {
    file: FileAttach,
    index: number,
    name?: string,
    nameSort?: string,
    /**
     * form 모드로 토큰값을 같이 보냅니다
     */
    authToken?: string | null,

    preview: React.MutableRefObject<FileViewerDialog | null>,
    onDelete: (index: number, fileName: string) => void,
}
const FileAttachItem = forwardRef<HTMLDivElement, FileAttachItemProps>((props, ref) => {
    const {theme} = useTheme();

    const getAuthTokenParam = () => isEmptyString(props.authToken) ? null : `${HEADER_AUTH}=${encodeURIComponent(props.authToken + '')}`;
    const getViewerType = (kind: FileKind): FileViewerType | null => {
        switch (kind) {
            case 'Image':
                return 'Image';
            case 'PDF':
                return 'PDF';
            //case 'Text': return 'Text';
            case 'Audio':
                return 'Audio';
            case 'Video':
                return 'Video';
            default:
                return null;
        }
    }

    const getURL = (file: FileAttach, isToken?: boolean) => (isToken ?? true) ? file.attach!.setParam(getAuthTokenParam()).getURL() : file.attach!.getURL();
    const getButtonColor = () => 'black';//theme.darkMode ? 'black' : 'white';
    function showPreviewDialog(file: FileAttach)
    {
        if(props.preview != null && props.preview.current != null)
        {
            const viewerType = getViewerType(file.kind);
            if (viewerType != null)
            {
                //로컬 파일일 때
                if (file.attach != null) props.preview.current.open({ type: viewerType, title: file.name, fileOrURL: getURL(file)}).then();
                else if (file.file != null) props.preview.current.openAsync({ type: viewerType, title: file.name, fileOrURL: file.file}).then();
                else alert("잘못된 파일 데이터 입니다");
            }
            else alert("지원되지 않는 파일형식입니다");
        }
    }

    const getPreviewButton = (file: FileAttach) =>
    {
        const viewerType = getViewerType(file.kind);
        return viewerType == null ? null : <a onClick={() => showPreviewDialog(file)}><IconFileSearch size={32} color={getButtonColor()}/></a>;
        /*
        if(file.isImage()) return <a onClick={() => showPreviewDialog(file)}><IconPhoto size={32}/></a>;
        else if(file.type.includes("pdf")) return <a onClick={() => showPreviewDialog(file)}><IconPdf size={32}/></a>;
         */
    }

    const getDownloadLink = (file: FileAttach) => {
        if (isWVMode()) {
            const data: RNDataDownload = {
                method: 'GET',
                url: new URL(getURL(file), location.origin).href,
                mime: file.type,
                name: file.name,
                size: file.size,
                header: {"Authorization": props.authToken ?? ''}
            }
            return <a style={{cursor: 'pointer'}} onClick={() => sendRNData({type: 'DOWNLOAD', data: data})}><IconFileDownload size={32} color={getButtonColor()}/></a>;
        }
        else if(isEmptyString(props.authToken))
        {
            return <Link to={getURL(file)} download target="_blank"><IconFileDownload size={32} color={getButtonColor()}/></Link>;
        }
        else
        {
            return (
                <form method='post' target={isWVMode() ? '_self' : '_blank'} action={getURL(file)}>
                    <input type='hidden' name={HEADER_AUTH} value={props.authToken!}/>
                    <button type='submit'><IconFileDownload size={32} color={getButtonColor()}/></button>
                </form>
            )
        }
    }

    return (
        <div style={thumbBoxStyle}>
            <div style={thumbStyle} key={props.file.name}>
                <div style={thumbInnerStyle} className={styles.attach_file + " attach_file"}>
                    <div className={styles.attach_file_blur}></div>
                    {props.nameSort == null || props.file.attach == null ? '' : <input type='hidden' name={props.nameSort} value={`${props.file.attach.Key}_${props.index}`}/>}
                    {props.name == null && props.file.attach != null ?
                        <p className={styles.attach_file_cancel}>
                            {/*getPreviewButton(file)*/}
                            {/*<a onClick={() => showPreviewDialog(file)}><IconZoomScan size={32}/></a>*/}
                            {/*<a onClick={() => showPreviewDialog(file)}><IconZoomInArea size={32}/></a>*/}
                            {getPreviewButton(props.file)}
                            {getDownloadLink(props.file)}
                        </p>
                        :
                        <p className={styles.attach_file_cancel}>
                            {getPreviewButton(props.file)}
                            <a onClick={() => props.onDelete(props.index, props.file.name)}>
                                <IconCircleXFilled size={32}/>
                            </a>
                        </p>
                    }
                    <Thumb file={props.file}/>
                </div>
            </div>
            <a className={styles.file_name} download target="_blank" rel="noreferrer"
               href={props.file.attach == null ? undefined : props.file.attach.getURL()}
               style={props.file.attach == null ? undefined : {color: COLOR_LINK_DEFAULT}}>
                {props.file.name}
                <div style={{ color: theme.darkMode ? 'white' : 'black', fontWeight: 'bold' }}>
                    {`(${humanFileSize(props.file.size, true)})`}
                </div>
            </a>
            {props.name == null ? "" : <FileInput name={props.name} file={props.file}/>}
        </div>
    )
})
FileAttachItem.displayName = "FileAttachItem";

export default FileAttachItem;


export const FileAttachTemp = forwardRef<any, FileAttachItemProps>(({file, index, name}, ref) =>
{
    return (
        <div style={thumbBoxStyle}>
            <div style={thumbStyle}>
                <div style={thumbInnerStyle}>
                    <div className={styles.attach_file + " attach_file"}
                         style={{width: `120px`, height: `120px`, background: 'green'}}></div>
                </div>
            </div>
            <a className={styles.file_name} download target="_blank" rel="noreferrer"
               href={file.attach == null ? undefined : file.attach.getURL()}
               style={file.attach == null ? undefined : {color: COLOR_LINK_DEFAULT}}>
                {file.name}
                <div style={{
                    color: 'black',
                    fontWeight: 'bold'
                }}>{`(${humanFileSize(file.size, true)})`}</div>
            </a>
            {name == null ? "" : <FileInput name={name} file={file}/>}
        </div>
    )
})
FileAttachTemp.displayName = "FileAttachTemp";