import React, {Component, useEffect, useMemo, useRef, useState} from 'react';
import Modal from "react-modal";
import UserDataSimple from "data/API/User/UserDataSimple";
import {isEmptyString, randomKey} from "@hskernel/hs-utils";
import {printError} from "Define";
import UserSimpleViewer from "common/control/viewer/UserSimpleViewer";
import UserImageViewer from "common/control/viewer/UserImageViewer";
import PageControl, {calcCount, calcOffset} from 'common/control/PageControl';
import {useNavigate} from "react-router-dom";
import UserData from "data/API/User/UserData";
import UserCountData from "data/API/User/UserCountData";
import {UserSearchData} from "../../data/API/User/UserDataBase";

const UserListPage = () =>
{
    const PageCount = 9;

    const Navigate = useNavigate();
    const [search, setSearch] = useState<UserSearchData | undefined>();
    const pageControl = useRef<PageControl | null>(null);

    const [users, setUsers] = useState<UserDataSimple[] | null>(null);
    const [total, setTotal] = useState<UserCountData | null>();
    const getTotal = () => total == null ? 0 : total.Total;
    const getCount = () => total == null ? 0 : total.Count;

    async function RefreshAsync(search?: UserSearchData, offset?: number, count?: number): Promise<boolean>
    {
        setUsers(null);
        try
        {
            //재직자만...
            if (search == null) search = {isEmployee: true};
            else search.isEmployee = true;

            const total = await UserData.GetUsersCountAsync(search);
            setTotal(total);

            const users = await UserData.GetUsersAsync(search, offset, count, true);
            setUsers(users ?? []);
            return users != null;
        }
        catch (error: any) { printError(error) }

        return false;
    }

    useEffect(() => { RefreshAsync(search, 0, PageCount).then(); }, []);
    useEffect(() => { if(pageControl.current != null) pageControl.current.setTotalAsync(getCount()).then()}, [total]);

    const onPage = (page: number) =>
    {
        const offset = calcOffset(getCount(), page, PageCount);
        const count = calcCount(getCount(), page, PageCount);
        return RefreshAsync(search, offset, count);
    }

    const UserModalRef = useRef<UserModal | null>(null);
    const openModal = (user: UserDataSimple) => { if(UserModalRef.current != null) UserModalRef.current.openUser(user); }

    const _users = useMemo<UserDataSimple[] | null>(() => users, [users]);
    return (
        <>
            <UserModal ref={UserModalRef}/>
            <div className="col-12 col-md-9 d-flex flex-column">
                <div className="card-body" style={{minHeight: "800px"}}>
                    <h2 className="mb-4">사원 목록</h2>
                    <div className="row row-cards">
                        {
                            (_users ?? [...Array(PageCount)]).map((user, i) =>
                                <div key={randomKey(i)}  className="col-md-6 col-lg-4">
                                    <UserSimpleViewer user={user} showButton={true} detail={false} onClick={(user) => openModal(user)}/>
                                </div>)
                        }
                    </div>
                    <PageControl ref={pageControl} total={getCount()} count={PageCount} onChange={onPage} navigate={Navigate}/>
                </div>
            </div>
        </>
    )
}
export default UserListPage;

type UserModalProps = {
    user?: UserDataSimple
}
type UserModalState = UserModalProps & {
    isOpen: boolean,
}
class UserModal extends Component<UserModalProps, UserModalState>
{
    constructor(props: UserModalProps)
    {
        super(props);
        this.state = {
            user: props.user,
            isOpen: false
        }

        this.open = this.open.bind(this);
        this.openUser = this.openUser.bind(this);
        this.setUser = this.setUser.bind(this);
    }

    private modalStyle = {
        content: {
            maxWidth: "360px",
            height: "400px",
            margin: "100px auto",
            background: "var(--tblr-bg-surface)"
        },
        overlay: {
            background: "rgba(0,0,0,0.5)",
            zIndex: "9"
        }
    }

    open = (isOpen: boolean) => this.setState({...this.state, isOpen: isOpen});
    openUser = (user: UserDataSimple) => this.setState({...this.state, isOpen: true, user: user});
    setUser = (user: UserDataSimple) => this.setState({...this.state, user: user});

    private getName(user?: UserDataSimple)
    {
        if(user == null) return "";

        if(isEmptyString(user.NameKorean) && isEmptyString(user.NameEnglish)) return user.Name ?? "";
        else
        {
            if(isEmptyString(user.NameKorean)) return `${user.Name} (${user.NameEnglish})`;
            else if(isEmptyString(user.NameEnglish)) return `${user.Name} (${user.NameKorean})`;
            else return `${user.Name} (${user.NameKorean} / ${user.NameEnglish})`;
        }
    }
    render()
    {
        const user = this.state.user;
        const name = this.getName(user);
        return (
            <Modal isOpen={this.state.isOpen} style={this.modalStyle}>
                {user == null ? "" :
                    <div className="p-2">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div className="avatar avatar-xl">
                                    <UserImageViewer user={user}/>
                                </div>
                            </div>
                            <div className="col text-truncate">
                                <h3 className="mb-1">{name}</h3>
                                <div className="d-block text-secondary text-truncate mt-n1">
                                    <b>직급</b> {user.PositionName1}
                                    <span> | </span>
                                    <b>직책</b> {user.PositionName2}
                                </div>
                                <div className="d-block text-secondary text-truncate mt-n1">
                                    <b>부서</b> {user.DeptName}
                                </div>
                            </div>
                        </div>
                        <hr style={{margin: "1rem 0"}}/>
                        <h4>사원 정보</h4>
                        <table className="table" style={{borderTop: "2px solid #555"}}>
                            <tbody>
                            <tr>
                                <td style={{background: "#f5f5f5",width:"40%"}}>이메일 주소</td>
                                <td>{isEmptyString(user.EmailCompany) ? "-" : user.EmailCompany}</td>
                            </tr>
                            <tr>
                                <td style={{background: "#f5f5f5"}}>내선번호</td>
                                <td>{isEmptyString(user.TelCompany) ? "-" : user.TelCompany}</td>
                            </tr>
                            <tr>
                                <td style={{background: "#f5f5f5"}}>팩스번호</td>
                                <td>{isEmptyString(user.TelFax) ? "-" : user.TelFax}</td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="row">
                            <button type="button" className="btn" onClick={() => this.open(false)}>닫기</button>
                        </div>
                    </div>
                }
            </Modal>
        )
    }
}
