import React, {useEffect, useState} from "react";

type InputRadioYNProps = {
    name?: string,
    value?: boolean,
    /**
     * ㄱㅏㅂㅅ이 변경되면 발생합니다 (true 면 갑을 변결하지 않습니다)
     * @param value
     */
    onChange?: (value: boolean) => boolean | void
}

const InputRadioYN = (props: InputRadioYNProps) =>
{
    const [check, setCheck] = useState<boolean | undefined>(props.value);
    function onCheck(checked?: boolean)
    {
        const oldCheck = check;
        setCheck(checked);
        if (checked != undefined && props.onChange != null && props.onChange(checked)) setCheck(oldCheck);
    }

    return (
        <div>
            <input type='hidden' name={props.name} value={ check == null ? undefined : (check ? "true" : "false") }/>
            <label className="form-check form-check-inline">
                <input type="radio" className="form-check-input" onChange={() => onCheck(true)} checked={check}/>
                <span className="form-check-label">예</span>
            </label>
            <label className="form-check form-check-inline">
                <input type="radio" className="form-check-input" onChange={() => onCheck(false)} checked={check == undefined ? undefined : !check}/>
                <span className="form-check-label">아니오</span>
            </label>
        </div>
    )
}

export default InputRadioYN;