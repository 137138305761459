import React, {Component, FormEvent} from "react";
import Modal from 'react-modal';
import UserDataSimple from "data/API/User/UserDataSimple";
import UserSettingsAdminData from "data/API/User/UserSettingsAdminData";
import {printError} from "Define";
import InputRadioYN from "common/input/InputRadioYN";

const passwordStyle = {
    content: {
        maxWidth: "340px",
        height:"fit-content",
        margin:"20px auto",
        background: "transparent",
        border:"none",
        inset:"0",
        padding:"0"
    },
    overlay: {
        background: "rgba(0,0,0,0.5)",
        zIndex: "99"
    }
}

type UserSettingsDialogState = {
    user?: UserDataSimple,
    setting?: UserSettingsAdminData,
    isOpen: boolean,
    isBusy: boolean,
    errorCount: number | null,
    errorCountSimple: number | null,
}

export default class UserSettingsDialog extends Component<any, UserSettingsDialogState>
{
    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: false,
            isBusy: false,
            errorCount: null,
            errorCountSimple: null,
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);

        this.onCheckMail = this.onCheckMail.bind(this);
    }

    private async onSubmit(e: FormEvent<HTMLFormElement>)
    {
        e.preventDefault();
        let open = true;

        const form = UserSettingsAdminData.fromForm(e.currentTarget);
        try
        {
            this.setState({...this.state, isBusy: true});
            await form.SaveAdminAsync(this.state.user!.LoginID);

            alert("성공적으로 설정을 적용하였습니다");
            open = false;
        }
        catch (ex: any) { printError(ex); }
        finally { this.setState({...this.state, isBusy: false, isOpen: open}); }
    }

    public openDialog(user: UserDataSimple)
    {
        let open = true;
        let _setting: UserSettingsAdminData | undefined = undefined;

        this.setState({...this.state, setting: undefined, user: user, isOpen: open, isBusy: true});
        UserSettingsAdminData.GetSettingsAsync(user.LoginID)
            .then((setting) =>
            {
                if(setting == null)
                {
                    alert("설정을 가져올 수 없습니다");
                    open = false;
                }
                else _setting = setting;
            })
            .catch(error =>
            {
                printError(error);
                open = false;
            })
            .finally(() =>
            {
                const errorCount = _setting == null ? null : _setting.PasswordErrorCount ?? null;
                const errorCountSimple = _setting == null ? null : _setting.SimplePWErrorCount ?? null;
                this.setState({...this.state, isBusy: false, isOpen: open, setting: _setting, errorCount: errorCount, errorCountSimple: errorCountSimple});
            });
    }
    private closeDialog(){ this.setState({...this.state, isOpen: false}); }

    private onCheckMail(check: boolean)
    {
        if (this.state.setting?.EmailUse && !check && !confirm('메일 사용을 중지하게 되면\n해당 메일 계정 및 모든 메일도 삭제되며 복구할 수 없습니다.\n\n정말 중지 하시겠습니까?'))
            return true;
    }

    private Loading(){ return <div className="placeholder">로딩중입니다...</div> }
    render()
    {
        return (
            <Modal ariaHideApp={false} isOpen={this.state.isOpen} style={passwordStyle}>
                {this.state.user == null ?
                    <h1>잘못된 접근입니다</h1>
                    :
                    (
                        this.state.isBusy ?
                            <div>잠시만 기다려 주십시오...</div>
                            :
                            <form className="card placeholder-glow" onSubmit={this.onSubmit}>
                                <div className="card-header">
                                    <p className="col">{this.state.user.NameKorean} 계정 설정</p>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <p className="mb-2">사용 정지</p>
                                        <div className="mb-2">
                                            {this.state.setting == null ? this.Loading() : <InputRadioYN name="UsageStop" value={this.state.setting.UsageStop}/>}
                                        </div>
                                        <p className="mb-2">보안 항목 간편 비밀번호 사용</p>
                                        <div className="mb-2">
                                            {this.state.setting == null ? this.Loading() : <InputRadioYN name="SimplePWUse" value={this.state.setting.SimplePWUse}/>}
                                        </div>
                                        <p className="mb-2">회사 메일 사용</p>
                                        <div className="mb-2">
                                            {this.state.setting == null ? this.Loading() : <InputRadioYN name="EmailUse" value={this.state.setting.EmailUse} onChange={this.onCheckMail}/>}
                                        </div>
                                        <p className="mb-2">ERP 사용</p>
                                        <div className="mb-2">
                                            {this.state.setting == null ? this.Loading() : <InputRadioYN name="ERPUse" value={this.state.setting.ERPUse}/>}
                                        </div>
                                        <p className="mb-2">비밀번호 오류 횟수</p>
                                        <div className="mb-2">
                                            {this.state.setting == null ? this.Loading() :
                                                <div>
                                                    <a>{this.state.errorCount ?? '-'} 회</a>&nbsp;&nbsp;
                                                    <input type="hidden" value={this.state.errorCount ?? undefined}/>
                                                    <button type="button" className="btn" onClick={() => this.setState({...this.state, errorCount: 0})}>초기화</button>
                                                </div>}
                                        </div>
                                        <p className="mb-2">간단 비밀번호 오류 횟수</p>
                                        <div className="mb-2">
                                            {this.state.setting == null ? this.Loading() :
                                                <div>
                                                    <a>{this.state.errorCountSimple ?? '-'} 회</a>&nbsp;&nbsp;
                                                    <input type="hidden" value={this.state.errorCountSimple ?? undefined}/>
                                                    <button type="button" className="btn" onClick={() => this.setState({...this.state, errorCountSimple: 0})}>초기화</button>
                                                </div>}
                                        </div>
                                    </div>

                                </div>
                                <div className="card-footer text-end">
                                    <input type="submit" className="btn btn-primary" disabled={this.state.isBusy} value="저장"/>&nbsp;&nbsp;
                                    <button type="button" className="btn" disabled={this.state.isBusy} onClick={this.closeDialog}>취소</button>
                                </div>
                            </form>
                    )
                }
            </Modal>
        )
    }
}