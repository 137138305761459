import "../../style/ApprovalDocument.css";
import styles from "../../component/DocumentViewerControl.module.css";
import React, {ChangeEvent, createRef, FormEvent, forwardRef, useImperativeHandle, useRef} from 'react';
import {useEffect, useState} from "react";
import {useNavigate, useParams } from "react-router-dom";
import Popup from 'react-new-window'
import FileAttachControl from "common/control/FileAttachControl";
import {getParam, isEmptyString, isNullorWhiteSpace, randomKey} from "@hskernel/hs-utils";
import {SendValueAsync} from "API";
import {useAuth} from "context/AuthContext";
import {calcHeight, isShowMenu, setWindowClosePrevent} from "Define";
import {
    DATETIME_FORMAT, DOC_CONTENT_WIDTH,
    URL_API_APPROVAL_DOCU_FORMAT,
    URL_APPROVAL_VIEW
} from "../../Define";
import AuthData from "Auth";
import UserDataBase from "data/API/User/UserDataBase";

import PasswordInputModal from "common/control/PasswordInputModal";
//import Summernote from "common/control/editor/TextAreaSummernote";
//import CKEditor from "common/control/editor/TextAreaCKEditor";
import NamoSE from "common/control/editor/TextAreaNamoSE";
import DateTimeControl from "common/control/DateTimeControl";
import DocumentRefSelectView from "../../component/selector/DocumentRefSelectView";
import LineSelectMultiModal from "../../component/selector/ApprovalLineMultiSelectModal";
import {ApprovalLineMultiData} from "../../component/selector/ApprovalLineMultiSelect";
import DocumentFormHSAIDA from "../../data/hsaida/DocumentFormHSAIDA";
import DocumentHSAIDA from "../../data/hsaida/DocumentHSAIDA";
import ApprovalDocumentTypeData from "../../data/ApprovalDocumentTypeData";
import {ApprovalSelectorData} from "common/control/selector/internal/ISelector";
import ApprovalStatus, {
    APPROVER_KIND_AGREE,
    APPROVER_KIND_APPROVAL,
    APPROVER_KIND_EXEC,
    APPROVER_KIND_REF
} from "../../data/ApprovalStatus";
import ApprovalLineControl, {ApprovalSelectorDataEx} from "../../component/ApprovalLineControl";
import DocumentViewerControl from "../../component/DocumentViewerControl";
import {useReactToPrint} from "react-to-print";
import axios from "axios";
import {STATUS_SAVE} from "../../data/ApprovalDocumentBase";
import {useMenuUI} from "context/MenuUIContext";
import PageLoadingTabler from "common/PageLoadingTabler";
import ApprovalLineExtra from "../../type/ApprovalLineExtra";
import parseHTML from "html-react-parser";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import {STATUS_APPROVAL_HOLD, STATUS_APPROVAL_PROGRESS} from "../../data/ApprovalStatusBaseData";
import {DisconnectNotiFirebase, DisconnectNotiSignalRAsync} from "App";

export type DocumentFormat = {
    DocumentClass: string,
    Text: string
}

export class DocumentState {
    public docData: DocumentHSAIDA;
    public docType: ApprovalDocumentTypeData;
    public docFormat: DocumentFormat | null;
}

type DocumentParams = {
    ClassOrID: string
}

export function Close(message?: string) {
    if(message != null) alert(message);
    history.back();
    window.close();
}

const DocumentWritePage: React.FC = () =>
{
    const now = new Date();
    const {auth} = useAuth();
    const {menuUI, setMenuUI} = useMenuUI();
    const navigate = useNavigate();
    const params = useParams<DocumentParams>();

    const [busy, setBusy] = useState(false);
    //const [queries] = useSearchParams();
    const [doc, setDoc] = useState<DocumentState | null>(null);
    const [comment, setComment] = useState<string | null>(null);
    const [apprOffset, setApprOffset] = useState<number>(-1);

    const [isReport, setIsReport] = useState(false);
    const isApprovalEdit = getParam("approval") == "true";
    const editor = useRef<NamoSE>(null);

    const passwordDialog = useRef<PasswordInputModal>(null);

    const getIsMobile = () => window.innerWidth <= 577;
    const [isMobile, setIsMobile] = useState(getIsMobile());
    const onWindowResize = useWindowResize();
    onWindowResize(() =>
    {
        setIsMobile(getIsMobile());
    });
    function onClose()
    {
        if (confirm("정말 저장하지 않고 페이지를 나가시겠습니까?"))
        {
            setWindowClosePrevent(false);
            Close();
        }
    }

    useEffect(() =>
    {
        async function Init()
        {
            const doc = new DocumentState();
            if (params.ClassOrID == null || isNullorWhiteSpace(params.ClassOrID)) Close("잘못된 접근 입니다");
            else
            {
                //문서 번호로 불러오기
                let docData = (await DocumentHSAIDA.GetDocument(params.ClassOrID, false));
                //문서 형식일떄
                if (docData == null)
                {
                    //파라미터의 문서 번호 가져오기
                    const docnum = getParam("doc");
                    if(!isEmptyString(docnum))
                    {
                        docData = (await DocumentHSAIDA.GetDocument(docnum!, false));
                        if(docData != null)
                        {
                            doc.docData = docData;
                            docData.DocumentStatus = STATUS_SAVE;
                            doc.docData.DocumentID = undefined;
                            doc.docData.WriteDate = now;
                        }
                    }

                    if (docData == null)
                    {
                        doc.docData = new DocumentHSAIDA();
                        doc.docData.DocumentStatus = STATUS_SAVE;
                        doc.docData.DocumentClass = params.ClassOrID;
                        doc.docData.WriteDate = now;
                        if (auth)
                        {
                            doc.docData.WriteNameKor = auth.user.NameKorean;
                            doc.docData.WriteDeptCode = auth.user.DeptCode;
                            doc.docData.WriteDeptName = auth.user.DeptName;
                        }
                    }
                }
                //실제 문서번호 일때
                else doc.docData = docData;

                //문서유형이 존재하지 않으면 404
                doc.docType = (await ApprovalDocumentTypeData.GetDocumentType(doc.docData.DocumentClass, true))!;
                if(doc.docData.DocumentClassName == null) doc.docData.DocumentClassName = doc.docType.ClassName;

                doc.docFormat = await SendValueAsync<DocumentFormat>(axios.get(`${URL_API_APPROVAL_DOCU_FORMAT}/${params.ClassOrID}`), false);

                //문서상태가 저장이 아닌 상태라면 (결재 중 수정) 결재한 위치 가져오기
                if(doc.docData.DocumentStatus != STATUS_SAVE && doc.docData.Approval != null)
                {
                    let i = 0;
                    for(; i < doc.docData.Approval.length; i++)
                    {
                        const data = doc.docData.Approval[i];
                        if(data.ApprovalStatus == STATUS_APPROVAL_PROGRESS || data.ApprovalStatus == STATUS_APPROVAL_HOLD || data.ApprovalStatus == null) break;
                    }

                    if(i == doc.docData.Approval.length) i -= 1;
                    //+1 를 하면 나도 제외
                    setApprOffset(i + 1);
                    setComment(doc.docData.Approval[i].ModifyComment);
                }

                setDoc(doc);
                document.title = "결재문서 " + (isEmptyString(doc.docData.DocumentID) ? `작성 (${doc.docType.ClassName})` : `수정 [${doc.docData.DocumentID}]`);
            }
        }

        if(auth != null)
        {
            if(isShowMenu())
            {
                if(!menuUI.hideLocate) setMenuUI({ hideLocate: true });
            }
            else
            {
                if(!menuUI.hideAll) { setMenuUI({hideAll: true}); return; }
                //알림 해제
                DisconnectNotiFirebase().then();
                DisconnectNotiSignalRAsync().then();
            }
            Init().then(() => setWindowClosePrevent(true));
        }
    }, [auth]);

    const _onSubmit = async (docForm:  DocumentFormHSAIDA, password?: string, comment?: string | null) =>
    {
        setBusy(true);
        try
        {
            const DocumentID = await docForm.Submit(password, comment, isReport);
            setWindowClosePrevent(false);

            const param: string[] = [];
            if(isShowMenu()) param.push(`menu=true`);
            navigate(`${URL_APPROVAL_VIEW}/${DocumentID}?${param.join('&')}`);

            if (window.opener != null) window.opener.location.reload();
        }
        catch(error: any)
        {
            setComment(comment ?? null);
            alert(error.message)
        }
        finally { setBusy(false); }
    }

    const form = useRef<HTMLFormElement | null>(null);

    /**
     *
     * @param {boolean} isReport true 면 저장 및 상신
     */
    function onSave(isReport: boolean)
    {
        if(form.current != null)
        {
            setIsReport(isReport);

            if(editor.current != null) editor.current.save();

            const docForm = DocumentFormHSAIDA.FromForm(new FormData(form.current));
            const message = doc == null ?
                docForm.CheckValid(STATUS_SAVE, null) :
                docForm.CheckValid(doc.docData.DocumentStatus, doc.docData.ERPText);
            if (message == null)
            {
                if(isApprovalEdit || isReport)
                {
                    if(passwordDialog.current != null)
                        passwordDialog.current.openDialog(true, docForm);
                }
                else _onSubmit(docForm).then();
            }
            else alert(message);
        }
    }

    const [preview, openPreview] = useState(false);
    const previewPopup = (doc: DocumentState) =>
    {
        if(editor.current != null)
        {
            doc.docData.Text = editor.current.getContents() ?? "";
            editor.current.save();
        }
        return (
            <Popup onUnload={() => openPreview(false)}
                   title={`문서 미리보기 [${doc.docType.ClassName}] - ${doc.docData.Title}`}
                   features={{width: DOC_CONTENT_WIDTH, height: calcHeight()}}>
                {/*<PreviewForm doc={doc.docData}/>*/}
                <div className="container-xl preview_wrap">
                    <DocumentViewerControl doc={doc.docData}/>
                </div>
            </Popup>
        )
    }

    return (
        doc == null || auth == null ?  <PageLoadingTabler message="문서를 불러오는 중입니다"/> :
            <>
                <PasswordInputModal ref={passwordDialog} showPassword={auth.setting.SimplePWUse} showComment={true} requireComment={!isReport} comment={comment}
                                    onOK={(password, comment, doc) => _onSubmit(doc, password, comment).then()}
                                    onCancel={(comment) => setComment(comment)}/>
                <form className="page" ref={form}>
                    <input type="hidden" name="DocumentClass" value={doc.docData.DocumentClass}/>
                    <div className="navbar navbar-expand-md d-print-none">
                        <div className="container-xl">
                            <p>전자결재</p>
                            <div className="navbar-nav flex-row order-md-last">
                                <div className="nav-item d-md-flex me-3">
                                    <div className="btn-list">
                                        {isApprovalEdit ? "" : <button type="button" className="btn" disabled={busy} onClick={() => onSave(true)}>상신</button>}
                                        <button type="button" className="btn" disabled={busy} onClick={() => onSave(false)}>저장</button>
                                        <button type="button" className="btn" onClick={() => openPreview(true)}>미리보기</button>
                                        <button type="button" className="btn" disabled={busy} onClick={onClose}>나가기</button>
                                        {preview ? previewPopup(doc) : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="container-xl">
                        <div className="card">
                            <div className="card-body">
                                <FormInternal ref={editor} docProps={doc} authProps={auth} apprOffset={apprOffset}/>
                                <div className="card approval_attach" style={{margin: "0 auto"}}>
                                    <div className="card-body">
                                        <FileAttachControl attachs={doc.docData.Attach} name="FormAttachFile[]" nameDelete="FormAttachFileDelete[]" nameSort="FormAttachSort[]"/>
                                    </div>
                                </div>
                            </div>
                            {
                                /*
                            <div className='card-footer'>
                                <div className="row ">
                                    <div className="col-xl-4">
                                        <div className="mb-3">
                                            <label className="form-label">보안등급</label>
                                            <div className="row g-2">
                                                <div className="col">
                                                    <input type="text" className="form-control" placeholder="1등급" disabled/>
                                                </div>
                                                <div className="col-auto">
                                                    <a href="#" className="btn btn-icon" aria-label="Button">
                                                        선택
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4">
                                        <div className="mb-3">
                                            <label className="form-label">중요도</label>
                                            <select className="form-select" id="select-users" value="">
                                                <option value="2">보통</option>
                                                <option value="3">긴급</option>
                                            </select>

                                        </div>
                                    </div>
                                    <div className="col-xl-4">
                                        <div className="mb-3">
                                            <label className="form-label">보존기간</label>
                                            <select className="form-select" id="select-users" value="">
                                                <option value="2">영구보존</option>
                                                <option value="3">1년</option>
                                                <option value="3">2년</option>
                                                <option value="3">3년</option>
                                                <option value="3">4년</option>
                                                <option value="3">5년</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                 */
                            }
                            <br/>
                        </div>
                        <br/>
                    </div>
                </form>
            </>
    )
}
export default DocumentWritePage;

type FormInternalProps = {
    docProps: DocumentState,
    authProps: AuthData,
    apprOffset: number,
}

/**
 *
 * @param setDocForm
 * @param {DocumentState} docProps
 * @param {AuthData} authProps
 * @returns {JSX.Element}
 * @constructor
 */
const FormInternal = forwardRef<NamoSE, FormInternalProps>(({docProps, authProps, apprOffset}, refEditor) => {
    const loading = createRef<HTMLHeadingElement>();

    const modal = createRef<LineSelectMultiModal>();
    const [approver, setApprover] = useState<ApprovalSelectorDataEx[]>([]);
    const [userRef, setUserRef] = useState<UserDataBase[]>([]);
    const [userExec, setUserExec] = useState<UserDataBase[]>([]);
    //const [loadDefault, setLoadDefault] = useState(true);
    //const editor = useRef<NamoSE>(null);
    const [isWriteDateChange, setWriteDateChange] = useState(docProps.docData.WriteDateChanged);

    //useImperativeHandle(ref, () => editor.current as NamoSE, [ref]);

    useEffect(() =>
    {
        async function Init()
        {
            const approver: ApprovalSelectorDataEx[] =
                docProps.docData.Approval?.map((data, index) =>
                {
                    // index < apprOffset 하면 자연스레 나는 제외됨
                    return {user: data.Approver, kind: data.Kind, final: data.IsFinal, index: index + 1, constable: index < apprOffset, extra: {final: data.IsFinal ?? false}}
                });
            setApprover(approver ?? []);

            const userRef = docProps.docData.RefUser?.map(data => data.Approver);
            setUserRef(userRef ?? []);

            const userExec = docProps.docData.ExecUser?.map(data => data.Approver);
            setUserExec(userExec ?? []);
        }

        Init().then(() => {
            if (loading.current != null) loading.current.style.display = "none";
        });

    }, [])
    const modalOpen = (kind: string) => {
        const data = new ApprovalLineMultiData();
        data.kind = kind;
        data.Approver = approver;
        data.UserRef = userRef.map(user => new ApprovalSelectorData(user, APPROVER_KIND_REF));
        data.UserExec = userExec.map(user => new ApprovalSelectorData(user, APPROVER_KIND_EXEC));

        if(modal.current != null)
        {
            const count = data.Approver.length + data.UserRef.length + data.UserExec.length;
            modal.current.openDialog(data, docProps.docType.DocumentClass/*, count == 0*/); //loadDefault
            //setLoadDefault(false);
        }
    }

    function saveApproval(data: ApprovalLineMultiData)
    {
        const appovals: ApprovalSelectorDataEx[] = data.Approver.map((data, index) => {
            return {user: data.user, kind: data.kind, constable: data.constable, index: index + 1, extra: data.extra}
        });
        //console.log(appovals)
        setApprover(appovals);
        setUserExec(data.UserExec.map(data => data.user));
        setUserRef(data.UserRef.map(data => data.user));

        function ConvertApprovalStatus(data: ApprovalSelectorData<ApprovalLineExtra>[], setIndex: boolean)
        {
            return data.map((data, index) =>
            {
                const tmp = new ApprovalStatus();
                tmp.Approver = data.user;
                tmp.Kind = data.kind;
                if(setIndex) tmp.Index = index + 1;
                return tmp;
            });
        }
        docProps.docData.Approval = ConvertApprovalStatus(data.Approver, true);
        docProps.docData.ExecUser = ConvertApprovalStatus(data.UserExec, false);
        docProps.docData.IsExecUser = data.UserExec.length > 0;
        docProps.docData.RefUser = ConvertApprovalStatus(data.UserRef, false);
        docProps.docData.IsRefUser = data.UserRef.length > 0;
    }

    const inputBind = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) =>
    {
        event.preventDefault();

        // @ts-ignore
        docProps.docData[event.target.name] = event.target.value;

        /*
        // @ts-ignore
        setDocForm({
            ...docFormProps,
            [event.target.name]: event.target.value
        });
         */
    }

    function getText() {
        if (docProps.docData.Text != null) return docProps.docData.Text;
        else if (docProps.docFormat != null) return docProps.docFormat.Text;
        else return null;
    }

    return (
        <div className="container-xl">
            <LineSelectMultiModal ref={modal} auth={authProps} showFinal={true} onSave={saveApproval}/>
            <h2 className={styles.approval_doc_title}>{docProps.docType.ClassName}</h2>
            <div style={{display: "hidden"}}>
                {approver.map((data, index) =>
                    index < apprOffset ? "" : <input key={randomKey(index)} type="hidden" name="FormApproval[]" value={`${data.user.LoginID}:${data.kind}:${data.extra.final}`}/>)
                }
            </div>
            <table className={styles.approval_form} style={{margin: "10px auto"}}>
                <tbody>
                    <tr>
                        <td className={styles.form_head}>문 서 번 호</td>
                        <td>
                            <select name="DocumentID" className={styles.approval_select}>
                                {
                                    isEmptyString(docProps.docData.DocumentID) ?
                                        <option value={''}>기본채번</option> :
                                        <option value={docProps.docData.DocumentID}>{docProps.docData.DocumentID}</option>
                                }
                            </select>
                        </td>
                        <ApprovalLineControl kind={APPROVER_KIND_APPROVAL} placeHolder="결재자를 선택해 주세요"
                                             onClick={() => modalOpen(APPROVER_KIND_APPROVAL)}
                                             members={approver.filter((value) => value.kind == APPROVER_KIND_APPROVAL)}/>
                    </tr>
                    <tr>
                        <td className={styles.form_head}>작 성 일 시</td>
                        <td>
                            {<DateTimeControl name="WriteDateText" format={DATETIME_FORMAT} className="date_select"
                                              value={docProps.docData?.WriteDate}
                                              onChange={(value, valueDate) => { setWriteDateChange(true); docProps.docData.WriteDate = valueDate ?? new Date() }}/>}
                            {/*{<input type="datetime-local" className="date_select" name="WriteDateText" defaultValue={docProps.docData == null ? '' : dateFormat(docProps.docData.WriteDate, 'yyyy-mm-dd HH:mm')}/>}*/}
                            &nbsp;
                            <input type="hidden" className="form-check-input m-0 align-middle" name="WriteDateChanged" value={isWriteDateChange ? "true" : "false"} onChange={(e) => setWriteDateChange(e.target.checked)}></input>
                        </td>
                    </tr>
                    <tr>
                        <td className={styles.form_head}>기 안 부 서</td>
                        <td style={{height: "39px"}}>
                            {docProps.docData.WriteDeptName}
                            <input type="hidden" name="WriteDeptCode" value={docProps.docData.WriteDeptCode ?? ''}/>
                        </td>
                        <ApprovalLineControl kind={APPROVER_KIND_AGREE} placeHolder="합의자를 선택해 주세요"
                                             onClick={() => modalOpen(APPROVER_KIND_AGREE)}
                                             members={approver.filter((value) => value.kind == APPROVER_KIND_AGREE)}/>
                    </tr>
                    <tr>
                        <td className={styles.form_head}>기&nbsp;&nbsp;안&nbsp;&nbsp;자</td>
                        <td style={{height: "39px"}}>{docProps.docData.WriteNameKor}</td>
                    </tr>
                    {docProps.docData.DocumentClass === "1010" &&
                        <>
                            <tr>
                                <td className={styles.form_head}>협조의뢰부서</td>
                                <td colSpan={3}>
                                    <input type="Text" className="approval_text" name="CollaboDeptName1"
                                           defaultValue={docProps.docData.CollaboDeptName1 ?? undefined}
                                           onChange={(e) => inputBind(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.form_head}>협조요청부서</td>
                                <td colSpan={3}>
                                    <input type="Text" className="approval_text" name="CollaboDeptName2"
                                           defaultValue={docProps.docData.CollaboDeptName2 ?? undefined}
                                           onChange={(e) => inputBind(e)}/>
                                </td>
                            </tr>
                        </>
                    }
                    <tr>
                        <td className={styles.form_head}>수신/참조</td>
                        <td colSpan={3}>
                            <input type="text" className="approval_text"
                                   value={userRef.map((data) => data.toString()).join(', ')} readOnly/>
                            <button type="button" className="approval_btn" onClick={() => modalOpen(APPROVER_KIND_REF)}>선택
                            </button>
                            <div>{
                                userRef.map((data, i) =>
                                    <input key={randomKey(i)} type="hidden" name="FormRefUser[]" value={data.LoginID}/>)}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={styles.form_head}>집&nbsp;행&nbsp;자</td>
                        <td colSpan={3}>
                            <input type="text" className="approval_text" value={userExec.map((data) => data.toString()).join(', ')} readOnly/>
                            <button type="button" className="approval_btn" onClick={() => modalOpen(APPROVER_KIND_EXEC)}>선택
                            </button>
                            <div>
                                {userExec.map((data, i) =>
                                    <input key={randomKey(i)} type="hidden" name="FormExecUser[]" value={data.LoginID}/>)}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={styles.form_head}>집&nbsp;행&nbsp;일&nbsp;자</td>
                        <td colSpan={3} style={{height: "39px"}}>

                        </td>
                    </tr>
                    <tr>
                        <td className={styles.form_head}>제&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;목</td>
                        <td colSpan={3}>
                            <input type="Text" className="approval_text" name="Title" style={{imeMode: "active"}}
                                   defaultValue={docProps.docData.Title}
                                   onChange={(e) => inputBind(e)}/>
                        </td>
                    </tr>
                    {docProps.docData.DocumentClass === "2010" || docProps.docData.DocumentClass === "2040" || docProps.docData.DocumentClass === "2050" &&
                        <>
                            <tr>
                                <td className={styles.form_head}>내용</td>
                                <td colSpan={3}><input type="Text" className="approval_text"/></td>
                            </tr>
                            <tr>
                                <td className={styles.form_head}>사전보고 수령자</td>
                                <td colSpan={3}><input type="Text" className="approval_text"/></td>
                            </tr>
                            <tr>
                                <td className={styles.form_head}>사전보고 유무</td>
                                <td colSpan={3}><input type="Text" className="approval_text"/></td>
                            </tr>
                            <tr>
                                <td className={styles.form_head}>사전보고 시각</td>
                                <td colSpan={3}><input type="Text" className="approval_text"/></td>
                            </tr>
                        </>
                    }
                    {docProps.docData.DocumentClass === "1050" &&
                        <>
                            <tr>
                                <td className={styles.form_head}>신청사유</td>
                                <td colSpan={3}><input type="Text" className="approval_text"/></td>
                            </tr>
                            <tr>
                                <td className={styles.form_head}>사유발생일</td>
                                <td colSpan={3}>
                                    <input type="date" className="date_select" name="IssueStartDate"
                                           onChange={(e) => inputBind(e)}/> ~
                                    <input type="date" className="date_select" name="IssueEndDate"
                                           onChange={(e) => inputBind(e)}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.form_head}>피신청인</td>
                                <td colSpan={3}>
                                    <div className="mb-3">
                                        <label className="form-label">주민등록번호</label>
                                        <input type="text" className="form-control" name="JuminNumber" onChange={(e) => inputBind(e)}/>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">성명</label>
                                        <input type="text" className="form-control" name="IssueName" onChange={(e) => inputBind(e)}/>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">신청인과의 관계</label>
                                        <input type="text" className="form-control" name="IssueRelation" onChange={(e) => inputBind(e)}/>
                                    </div>
                                </td>
                            </tr>
                        </>
                    }
                    {isEmptyString(docProps.docData.ERPText) ? "" :
                        <tr>
                            <td colSpan={4}>
                                {parseHTML(docProps.docData.ERPText)}
                            </td>
                        </tr>
                    }
                    <tr>
                        <td colSpan={4}>
                            <NamoSE ref={refEditor} name="Text" value={getText()} onChange={(text) => docProps.docData.Text = text}/>
                            {/* isEmptyString(docProps.docData.ERPDocumentID) ?
                                <CKEditor name="Text" value={getText()} onChange={(text) => docProps.docData.Text = text}/> :
                                <div className="approval_textarea_view">
                                    <input type="hidden" name="Text" value={docProps.docData.Text}/>
                                    {parseHTML(docProps.docData.Text)}
                                </div>
                            */}
                        </td>
                    </tr>
                    <tr>
                        <td className={styles.form_head}>참&nbsp;조&nbsp;문&nbsp;서</td>
                        <td colSpan={3}>
                            <DocumentRefSelectView name="FormDocumentRef" docs={docProps.docData.DocumentRef} onChange={(docs) => docProps.docData.DocumentRef = docs}/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
})
FormInternal.displayName = "FormInternal";

type PreviewFormProps = {
    doc: DocumentHSAIDA;
}
const PreviewForm = ({doc}: PreviewFormProps)  =>
{
    const viewRef = useRef<DocumentViewerControl>(null);
    const handlePrint = useReactToPrint({
        content: () => viewRef.current,
        documentTitle: doc?.DocumentID,
    });

    return (
        <div>print
            <div className="navbar navbar-expand-md d--none">
                <div className="container-xl">
                    <p>미리보기</p>
                    <div className="navbar-nav flex-row order-md-last">
                        <div className="nav-item d-md-flex me-3">
                            <div className="btn-list">
                                {/*<button type="button" className="btn" onClick={() => handlePrint()}>인쇄하기</button>*/}
                                <button type="button" className="btn" onClick={() => window.close()}>닫기</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div className="container-xl">
                <DocumentViewerControl ref={viewRef} doc={doc}/>
            </div>
        </div>
    )
}
