import {PathMaker, PathMakerMulti, randomKey} from "@hskernel/hs-utils";
import {Link, useLocation} from "react-router-dom";
import styles from './TopHeader.module.css';
import React, {useEffect, useState} from 'react';
//import { IconCategory2 } from '@tabler/icons-react';
import {Info as Modules} from "../Module";
import ModuleInfo, {checkModuleKindMatch, checkModuleMenu, ModuleMenu, ModuleMenuCategory} from 'modules/ModuleInfo';

import MenuArrowImage from './resource/menu-arrow.png';
import {useAuth} from "../context/AuthContext";
import {GetIcon, GetPath} from "./SideMenu";
import {useTheme} from "../context/ThemeContext";
import {ModuleMenuSubData} from "../data/API/Module/Menu/ModuleMenuData";
import ModuleMenuProperty from "../data/API/Module/Menu/ModuleMenuProperty";

type MenuProps = {
    property: ModuleMenuProperty,
    pathParent: string,
    admin: boolean,
}

type DetailMenuProps = MenuProps & {
    category: ModuleMenuCategory,
    moduleID: string,
    menu: ModuleMenu,
}
function DetailMenu({menu, pathParent, admin, property, category, ...props}: DetailMenuProps)
{
    const menuID = `${category.id}\\${menu.id}`;
    console.log(menuID);

    return checkModuleMenu(property, props.moduleID, menuID, menu.hide, menu.admin, admin) ? (
        <Link to={PathMaker(pathParent, menu.path)} key={randomKey()} className="dropdown-item" style={{backgroundColor: "var(--table-top-color)"}}>
            {menu.title}
        </Link>
    ) : <></>
}

type DropSubMenuProps = MenuProps & {
    module: ModuleInfo
    category: ModuleMenuCategory,
}
function DropSubMenu({module, pathParent, admin, property, category}: DropSubMenuProps)
{
    return checkModuleMenu(property, module.id, category.id, category.hide, category.admin, admin) ?
    (
        <div key={randomKey()} className="dropend" style={{backgroundColor: "var(--table-title)"}}>
            <a className={category.menu.length > 0 ? "dropdown-item dropdown-toggle" : "dropdown-item"}
               data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-expanded="false">
                <img src={MenuArrowImage} alt="▼"/>
                {category.title}
            </a>
            <div className="dropdown-menu">
                {category.menu.map((menu, i) => <DetailMenu key={randomKey(i)} menu={menu} category={category} property={property} moduleID={module.id} pathParent={PathMaker(pathParent, category.path ?? "")} admin={admin}/>)}
            </div>
        </div>
    ) : <></>
}

type SideMenuMobile = {
    isClick?: boolean,

    onIsClick?: React.Dispatch<React.SetStateAction<boolean>>
}

function SideMenuMobile({isClick, onIsClick}: SideMenuMobile) {
    const {admin, menuProperty} = useAuth();
    const {theme, setDarkMode} = useTheme();

    const location = useLocation();

    useEffect(() => reset(), [location]);
    useEffect(() => console.log(isClick), [isClick]);

    function reset() {
        if (onIsClick != null) onIsClick(false);
    }

    return (
        <header className={`navbar-expand-md ${styles.mobile_header}`}>
            <div className={`navbar-collapse collapse ${styles.navbar_menu}`}
                 style={{height: "100%!important", display: "block"}}>
                {isClick ? <div className="navbar">
                    <div className="container-xl">
                        <ul className="navbar-nav">
                            {Modules.map((module, i) => {
                                return checkModuleMenu(menuProperty, module.id, "", false, module.admin, admin) ? (
                                    <li key={randomKey(i)} className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-expanded="false">
                                            {GetIcon(module, theme.darkMode, true)}&nbsp;
                                            <span className="nav-link-title">{module.title}</span>
                                        </a>
                                        <div className="dropdown-menu">
                                            <div className="dropdown-menu-columns">
                                                <div className="dropdown-menu-column">
                                                    {module.category.map(category => <DropSubMenu key={randomKey(i)} module={module} category={category} property={menuProperty} pathParent={GetPath(module.id)} admin={admin}/>)}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ) : ""
                            })}
                        </ul>
                    </div>
                </div>:""}
            </div>
        </header>
    );
}

export default SideMenuMobile;