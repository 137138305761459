import React, {Component, createRef, CSSProperties, MouseEvent, useState} from "react";
import PageControl, {calcCount, calcMaxPage, calcOffset} from "./PageControl";
import {NavigateFunction} from "react-router-dom";
import {dateFormat, getDataByKey, randomKey, randomString} from "@hskernel/hs-utils";
import styles from './TableListControl.module.css';
import LongPressable from 'lib/react-longpressable';
import {isDebugMode} from "../../Define";
import {element} from "prop-types";

type BooleanPromiseNullable = boolean | void | Promise<boolean | void>;

export function GetItemStyle(width: number, clickable?: boolean, isLeft?: boolean): CSSProperties {
    return {
        width: `${width}%`,
        textAlign: isLeft == null ? "center" : (isLeft ? "left" : "right"),
        borderRight: "1px solid var(--tblr-border-color-translucent)",
        cursor: clickable ? "pointer" : "default",
    }
}

function GetHeaderStyle(width?: string | number | null): CSSProperties {
    return {
        width: width ?? undefined,
        textAlign: "center",
        borderRight: "1px solid var(--tblr-border-color-translucent)",
    }
}

export const TableItemType = {

    /**
     * 기본값 (렌더링 콜백이 존재하면 렌더링 콜백이 실행됩니다)
     */
    Default: null,
    /**
     * Date 형식이어야 함!! (yyyy-mm-dd HH:MM:ss)
     */
    DateTime: "yyyy-mm-dd HH:MM:ss",
    /**
     * Date 형식이어야 함!! (yyyy-MM-dd)
     */
    Date: "yyyy-mm-dd",
    /**
     * Date 형식이어야 함!! (HH:MM:ss)
     */
    Time: "HH:MM:ss",
}

export type TableItemHeaderBase<T> = {
    /**
     * 클릭이벤트 발생 여부
     */
    clickable?: boolean,
    /**
     * 컬럼(열)명
     */
    name: string,
    /**
     * 아이템 스타일
     */
    styleItem: CSSProperties,
    /**
     * 아이템 클래스명
     */
    classNameItem?: string
    /**
     * 데이터 바인딩 키
     */
    key: string,
    /**
     * 데이터 바인딩 타입 (기본값: TableItemType.Default)
     */
    type?: string,
    /**
     * 아이템을 그릴시 해당 셀 콜백 (바인딩타입이 TableItemType.Default 일때만 발생됩니다)
     * @param value
     * @param header
     */
    onRender?: (value: any, header: TableItemHeaderBase<T>, item: T, renderValue: string) => any,
    /**
     * 해당 이벤트가 존재하면 해당 이벤트가 대신 실행됩니다
     */
    onClick?: (header: TableItemHeaderBase<T>, item: T | undefined) => void,
}

/**
 * 데스크탑 테이블용
 */
export type TableItemHeader<T> = TableItemHeaderBase<T> & {
    /**
     * 마우스를 위로 올릴시 발생하는 이벤트 입니다
     * @param isLeave true면 마우스가 컨트롤 안에, flase 면 컨트롤을 벗어난 상태 입니다
     * @param value
     * @param header
     * @param item
     */
    onHover?: (isLeave: boolean, value: any, header: TableItemHeaderBase<T>, item: T) => void,
}

/**
 * 우측으로 표시되는 위치를 설정합니다
 */
export type CardItemRight = "none" | "normal" | "title";

/**
 * 모바일 카드뷰용
 */
export type CardItemHeader<T> = TableItemHeaderBase<T> & {
    /**
     * 우측으로 표시되는 위치를 설정합니다 (첫번째인 기본값은 해당하지 않습니다)
     */
    isRight?: CardItemRight
};

/**
 * 테이블 새로고침 시 데이터
 */
export type TableListData<T> = {
    data: T[],
    /**
     * 지정하지 않으면 총 갯수 설정을 안합니다
     */
    total?: number,
}

type TableListControlProps<T> = {
    onInit?: () => Promise<number>,
    /**
     * 처음에 자동 새로고침 호출 여부
     */
    autoRefresh: boolean,
    /**
     *
     */
    headerTable: TableItemHeader<T>[],
    /**
     * 모바일용 카드뷰 헤더
     */
    headerCard?: CardItemHeader<T>[],
    /**
     * 아이템 클릭 시 발생하는 이벤트 입니다
     * @param key
     * @param value
     */
    onItemClick?: (value: any, header: TableItemHeaderBase<T>, item: T, index: number) => BooleanPromiseNullable,
    /**
     * 아이템 길게 클릭 시 발생하는 이벤트 입니다 (없으면 컨텍스트 창 메뉴가 표시됩니다)
     * @param key
     * @param value
     */
    onItemLongClick?: (item: T) => void,
    /**
     * 새로고침 및 페이지 이동시 발생합니다
     * @param offset 오프셋 입니다
     * @param count 가져올 갯수 입니다
     * @param page 현재페이지 입니다
     * @param total 총 갯수 입니다
     */
    onRefresh: (offset: number, count: number, page: number, total: number) => Promise<TableListData<T>> | TableListData<T>,
    /**
     *
     */
    onRenderHeader?: (control: TableListControl<T>, isMobile: boolean, isLoading: boolean) => React.ReactElement,
    /**
     * 아이템 총 갯수 입니다
     */
    total?: number
    /**
     * 한번에 보여질 갯수 입니다 (기본값: 10)
     */
    count?: number,
    /**
     * 맨 앞에 체크박스 여부입니다
     */
    checkable?: boolean,
    /**
     * 페이지네이션 숨김여부 입니다
     */
    hidePage?: boolean,
    /**
     * 아이템 체크시 발생하는 이벤트 입니다
     * @param item
     * @param index
     */
    onChecked?: (item: T, checked: boolean, index: number) => void;
    /**
     * 체크 버튼 활성화 렌더링
     * @param {TableItemHeaderBase<T>} header
     * @param {TableListItem<T>} item
     * @returns {boolean} 체크 버튼 활성화 여부
     */
    onCheckRender?: (item: T) => boolean,
    navigate?: NavigateFunction,
    /**
     * 한번에 보여질 페이지 갯수
     */
    maxPage?: number
}

class TableListControlState<T>
{
    count = 10;
    total = -1;
    page = 1;
    data: TableListItem<T>[] = [];
    check: Map<number, T> = new Map<number, T>();
    checkableCount = 0;
    loading = false;

    getOffset = () => calcOffset(this.total, this.page, this.count);
    getCountCurrent = () => calcCount(this.total, this.page, this.count);

    setCount = (count: number) => {this.count = Math.max(count, 0); return this;}
    setTotal = (total: number) => {this.total = total; return this;}
    setPage = (page: number) => {this.page = Math.max(page, 1); return this;}
    setData = (data: TableListItem<T>[]) => {this.data = data; return this;}
    setCheck = (check: Map<number, T>) => {this.check = check; return this;}
    setCheckableCount = (count: number) => {this.checkableCount = count; return this;}
    setLoading = (loading: boolean) => {this.loading = loading; return this;}

    getTotal = () => this.total;

    clearPage = () =>
    {
        this.page = 1;

        const param = new URLSearchParams(location.search);
        const page = param.get('page');
        if(page != null) try { this.page = parseInt(page); } catch{ /**/ }

        return this;
    }

    toString = () => `page: ${this.page}\ntotal: ${this.total}\ncount: ${this.count}`;
}

type TableListItem<T> = {
    item: T,
    index: number,
    checkable: boolean
}

export default class TableListControl<T> extends Component<TableListControlProps<T>, TableListControlState<T>>
{
    public static URLParamKeys = [...PageControl.URLParamKeys];

    private PageMover = createRef<PageControl>();
    private _state: TableListControlState<any>;
    private ID = 'TableListControl_' + randomString(5);

    constructor(props: TableListControlProps<any>) {
        super(props);
        this.state = this._state = new TableListControlState<any>().setCount(props.count ?? 10).setTotal(props.total ?? 0);

        this._setStateAsync = this._setStateAsync.bind(this);

        this.refresh = this.refresh.bind(this);
        this.setCountAsync = this.setCountAsync.bind(this);
        this.setTotalAsync = this.setTotalAsync.bind(this);
        this.setPageAsync = this.setPageAsync.bind(this);
        this.setDataAsync = this.setDataAsync.bind(this);
        this.getCheckedData = this.getCheckedData.bind(this);
        this.checkAll = this.checkAll.bind(this);
        this.onRefreshAsync = this.onRefreshAsync.bind(this);
        this.onChecked = this.onChecked.bind(this);

        this.clearPageAsync = this.clearPageAsync.bind(this);
        this._clearPage = this._clearPage.bind(this);

        this.renderCheckAll = this.renderCheckAll.bind(this);
    }

    async componentDidMount()
    {
        if(this.props.autoRefresh)
        {
            if(this.props.onInit != null) await this.setTotalAsync(await this.props.onInit());
            await this.refresh();
        }
    }

    private _setStateAsync(state: TableListControlState<any>)
    {
        return new Promise<void>(resolve => this.setState(this._state = state, resolve));
    }

    /**
     * 항목을 새로고침합니다
     */
    public refresh = () => this.onRefreshAsync();

    public async setCountAsync(count: number)
    {
        await this._setStateAsync(this.state.setCount(count));
        if (this.PageMover.current) await this.PageMover.current.setCountAsync(count);
    }

    public async setTotalAsync(total: number)
    {
        await this._setStateAsync(this.state.setTotal(total));
        if (this.PageMover.current != null)
        {
            await this.PageMover.current.setTotalAsync(total);
            //this._state = this.PageMover.current.getPage();
        }
    }

    /**
     * 페이지 이동
     * @param page
     */
    public async setPageAsync(page: number)
    {
        if(page != this.state.page)
        {
            await this._setStateAsync(this.state.setPage(page));
            if (this.PageMover.current) await this.PageMover.current.setPageAsync(page);
        }
    }

    private _clearPage = () => this._state.clearPage();
    /**
     *페이지 초기화 (URL 파라미터로 페이지가 있으면 해당 페이지로...)
     */
    public async clearPageAsync(refresh?: boolean)
    {
        const state = this._clearPage();
        this.setState(this._state = state);
        if (this.PageMover.current != null) await this.PageMover.current.setPageAsync(state.page);
        if (refresh ?? true) await this.refresh();
        return state;
    }

    public async doRerenderAsync()
    {
        const data = [...this.state.data];
        await new Promise<void>(r => this.setState({...this.state, data: data}, r));
    }

    /**
     * 데이터 할당
     * @param datas
     * @param total
     */
    public async setDataAsync(datas: TableListItem<T>[], total?: number)
    {
        this.state.check.clear();
        let checkable = 0;
        datas.forEach(data => { if(data.checkable) checkable++; });

        let state = this.state.setData(datas).setCheckableCount(checkable).setLoading(false);
        if(total != null)
        {
            //총 갯수가 지정되면 페이지네이션에 할당
            state = state.setTotal(total);
            if (this.PageMover.current) await this.PageMover.current.setTotalAsync(total);
        }
        await this._setStateAsync(state);
    }

    /**
     * 체크된 항목 가져오기
     */
    public getCheckedData = () => Array.from(this.state.check.values());

    /**
     * 모두 체크/체크해제 하기
     * @param checked
     */
    public checkAll(checked: boolean)
    {
        const check = this.state.check;
        const backup = new Map<number, T>();
        if (this.props.onChecked != null) check.forEach((item, index) => backup.set(index, item));

        /*
         check.clear();
        if(checked)
        {
            this.state.data.forEach((value) =>
            {
                if(value.checkable) check.set(value.index, value.item);
            });
        }
        this.setState(this._state = this.state.setCheck(check));
         */

        this.state.data.forEach((value, index) =>
        {
            if(value.checkable)
            {
                if(checked)
                {
                    //백업용을 제외한 나머지를 체크했다고 이벤트 발생
                    if(!backup.has(index))
                    {
                        check.set(index, value.item);
                        if (this.props.onChecked != null) this.props.onChecked(value.item, checked, index);
                    }
                }
                else
                {
                    check.delete(index);
                    if (this.props.onChecked != null) this.props.onChecked!(value.item, checked, index);
                }
            }
        });

        this.setState(this._state = this.state.setCheck(check));
    }

    private async onRefreshAsync(page?: number)
    {
        const total = this._state.total;
        this.state.check.clear();

        try
        {
            let _page = page ?? 1;
            if (page == null) _page = this.props.navigate == null ? this._state.page : this._clearPage().page;

            const max = calcMaxPage(total, this._state.count);
            _page = Math.min(Math.max(_page, 1), max);

            this.state.setPage(_page);
            this.setState(this._state = this.state.setLoading(true));

            const offset = calcOffset(total, _page, this._state.count);
            const count = calcCount(total, _page, this._state.count);

            //console.log(calcCount(30, 3, 10)).
            //console.log(this._state);
            //console.log(this._state.getOffset());
            //console.log(this._state.getCountCurrent());

            const result = await Promise.resolve(this.props.onRefresh(offset, count, _page, total));
            const datas: TableListItem<T>[] = result.data.map((val, index) =>
            {
                let checkable = true;
                if(this.props.onCheckRender != null) checkable = this.props.onCheckRender(val);
                return {item: val, checkable: checkable, index: index}
            });
            await this.setDataAsync(datas, result.total);
            return datas.length > 0;
        }
        catch (e) { this.setState(this._state = this.state.setLoading(false)); return false;}
    }

    private onChecked(index: number, checked: boolean)
    {
        const check = this.state.check;
        const item = this.state.data[index].item;

        if (checked) check.set(index, item);
        else check.delete(index);

        this.setState(this._state = this.state.setCheck(check));

        if (this.props.onChecked) this.props.onChecked(item, checked, index);
    }

    private readonly renderCheckAll = (isMobile: boolean) =>
    {
        const id = `${this.ID}_check_all`;
        return (
            <>
                <input type="checkbox" id={id} className={`form-check-input ${isMobile ? 'm-0 align-middle' : ''}`}
                       aria-label="모든 항목 선택"
                       checked={this.state.checkableCount > 0 && this.state.check.size == this.state.checkableCount}
                       onChange={(e) => this.checkAll(e.target.checked)}
                />
                {isMobile ? <label htmlFor={id} className='m-2' style={{fontWeight: 'var(--tblr-font-weight-bold)', color: 'var(--tblr-secondary)'}}>전체</label> : ''}
            </>
        )
    }

    render() {
        return (
            <div className="docu_list_table">
                {this.props.headerCard == null ? "" :
                    <div className={styles.mobile_list}>
                        <div className='mb-2' style={{display: 'flex', alignItems: 'center', paddingLeft: "var(--tblr-card-spacer-x)"}}>
                            {this.props.checkable ? this.renderCheckAll(true) : ''}
                            {this.props.onRenderHeader == null ? '' : this.props.onRenderHeader(this, true, this.state.loading)}
                        </div>
                        {this.state.loading ?
                            [...Array(this.state.count)].map((dummy, i) => <CardItem key={randomKey(i)} index={i} header={this.props.headerCard} isLoading={true}/>) :
                            this.state.data.map((data, i) => <CardItem key={randomKey(i)} index={i} header={this.props.headerCard} data={data} checkable={this.props.checkable} checked={this.state.check.has(i)} onLongClick={this.props.onItemLongClick} onClick={this.props.onItemClick} onChecked={checked => this.onChecked(i, checked)}/>)
                        }
                    </div>}
                <div className={`${styles.pc_table}`}>
                    {this.props.onRenderHeader == null ? '' : this.props.onRenderHeader(this, false, this.state.loading)}
                    <table className='common_table' style={{borderBottom: "1px solid #dcdcdf"}}>
                        <thead>
                        <tr>
                            {this.props.checkable ?
                                <th style={{width:"5%", borderRight:"1px solid var(--tblr-border-color-translucent)"}}>
                                    {this.renderCheckAll(false)}
                                </th>
                                : ""
                            }
                            {this.props.headerTable.map((header, i) => (
                                <th key={randomKey(i)} style={GetHeaderStyle(header.styleItem?.width)}>{header.name}</th>)
                            )}
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.loading ?
                                [...Array(this.state.count)].map((dummy, i) =>
                                    <tr key={randomKey(i)}>
                                        {
                                            [...Array(this.props.headerTable.length + (this.props.checkable ? 1 : 0))].map((dummy, j) =>
                                                <td key={randomKey(i * j)} className="placeholder-glow">
                                                    <div className="placeholder col-12"></div>
                                                </td>
                                            )
                                        }
                                    </tr>
                                )
                                :
                                this.state.data.map((data, i) => (
                                    <tr key={randomKey(i)}>
                                        {this.props.checkable ?
                                            <td style={{textAlign:"center",borderRight:"1px solid var(--tblr-border-color-translucent)"}}>
                                                {data.checkable ?
                                                    <input type="checkbox" className="form-check-input m-0 align-middle"
                                                           aria-label="이 항목 선택"
                                                           onChange={(e) => this.onChecked(i, e.target.checked)}
                                                           checked={this.state.check.has(i)}/>
                                                    :
                                                    <input type="checkbox" className="form-check-input m-0 align-middle" style={{background:"#eee"}} disabled={true}/>
                                                }
                                            </td>
                                            : ""
                                        }
                                        {this.props.headerTable.map((header, j) => <TableItem key={randomKey(j)} index={i} header={header} data={data} onClick={this.props.onItemClick}/>)}
                                    </tr>)
                                )
                        }
                        </tbody>
                    </table>
                </div>
                <div style={{ display: this.props.hidePage ? "none" : "block" }}>
                    <PageControl ref={this.PageMover} total={this.state.total} count={this.state.count} max={this.props.maxPage}
                                    navigate={this.props.navigate} onChange={this.onRefreshAsync}/>
                </div>
            </div>
        );
    }
}

function parseValue(header: TableItemHeader<any>, data: TableListItem<any>): any
{
    const type = header.type;
    let value = null;
    let renderValue = '';
    if (header.key != null)
    {
        value = getDataByKey(data.item, header.key) ?? '';

        if (value != null)
        {
            if (typeof value == "object")
            {
                if (type == TableItemType.DateTime) renderValue = dateFormat(value, TableItemType.DateTime);
                else if (type == TableItemType.Date) renderValue = dateFormat(value, TableItemType.Date);
                else if (type == TableItemType.Time) renderValue = dateFormat(value, TableItemType.Time);
            }
            else renderValue = value.toString();
        }

        if (header.onRender) return header.onRender(value, header, data.item, renderValue);
    }
    return renderValue;
}

type TableItemProps<T> = {
    index: number,
    header: TableItemHeader<T>,
    data: TableListItem<T>,
    onClick?: (value: any, header: TableItemHeaderBase<T>, item: T, index: number) => BooleanPromiseNullable
}
const TableItem: React.FC<TableItemProps<any>> = ({header, data, onClick, index}) =>
{
    const [element, setElement] = useState(parseValue(header, data));
    async function _onClick()
    {
        if (header.clickable && onClick != null)
        {
            //클릭시 true 면 재 렌더링
            if (await onClick(data.item[header.key], header, data.item, index) == true) setElement(parseValue(header, data));
        }
    }
    function _onHover(isLeave: boolean, e: HTMLTableCellElement)
    {
        //밑줄효과 주기
        if (header.clickable && (header.styleItem ? header.styleItem.textDecoration == undefined : true)) e.style.textDecoration = isLeave ? "none" : "underline";
        if (header.onHover != null) header.onHover(isLeave, data.item[header.key], header, data.item);
    }

    return (
        <td style={header.styleItem}
            className={header.clickable ? `${header.classNameItem} table_click_effect` : header.classNameItem}
            onClick={_onClick}
            onMouseEnter={((e) => _onHover(false, e.currentTarget))}
            onMouseLeave={(e) => _onHover(true, e.currentTarget)}>
            {element}
        </td>
    )
}


type CardItemProps<T> = {
    index: number,
    isLoading?: boolean,
    checkable?: boolean,
    header?: CardItemHeader<T>[],
    data?: TableListItem<any>,
    checked?: boolean,
    onClick?: (value: any, header: TableItemHeaderBase<T>, item: T, index: number) => BooleanPromiseNullable,
    onChecked?: (checked: boolean, target: MouseEvent<HTMLInputElement>, index: number) => void,
    onLongClick?: (item: any) => void,
}
const CardItem: <T>(props: CardItemProps<T>) => JSX.Element = (props) =>
{
    /**
     * clickDefault 가 true 면
     */
    function onLongPress()
    {
        if (isDebugMode()) console.log("LONG PRESS!!")
        if (props.isLoading != true && props.data != null)
        {
            if (props.onLongClick != null) props.onLongClick(props.data.item);
            /*
            else
            {
                const header = props.header![0];
                const value = parseValue(header, props.data);
                //TODO: 컨텍스트 띄우기
            }
             */
        }
    }

    const isClickable = () => !(props.isLoading || props.onClick == null || props.header == null || props.header.length == 0);
    function onClick(header?: CardItemHeader<any>)
    {
        if (isClickable() && props.data != null)
        {
            if (header == null) header = props.header![0];
            props.onClick!(parseValue(header, props.data), header, props.data.item, props.index);
        }
    }

    function onChecked(e: MouseEvent<HTMLInputElement>)
    {
        //상위 이벤트 발생 중지
        e.stopPropagation();
        if (props.onChecked != null) props.onChecked(e.currentTarget.checked, e, props.index);
    }

    function getHeader()
    {
        if(props.isLoading) return <div className="placeholder" style={{display:"block"}}>...</div>;
        if(props.header == null || props.header.length == 0 || props.data == null) return <></>;

        const element = parseValue(props.header[0], props.data);
        return !props.checkable ? element : (
            <div style={{display: 'inline'}}>
                <input type='checkbox' className='form-check-input' style={{float: 'left', marginRight: '5px'}} onClick={onChecked} defaultChecked={props.checked}/>
                {element}
            </div>
        );
    }

    function getChild(header: CardItemHeader<any>, i: number)
    {
        function _onClick(e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>)
        {
            //상위 이벤트 발생 중지
            e.stopPropagation();
            e.preventDefault();

            if (header.onClick != undefined)
            {
                const item = props.data == undefined ? undefined : props.data.item;
                header.onClick(header, item);
            }
            else if(header.clickable)
            {
                onClick(header);
            }
            else onClick();
        }
        return props.isLoading ?
            <div key={randomKey(i)} className="placeholder">......</div> :
            <div key={randomKey(i)} style={header.styleItem} className={header.classNameItem} onClick={_onClick}>{props.data == null ? "" : parseValue(header, props.data)}</div>;
    }

    function getA()
    {
        if(props.isLoading) return <div className="placeholder">????-??-??</div>
        if(props.header == null) return "";
        return null;
    }

    return (
        <LongPressable onLongPress={onLongPress}>
            <div className="card mb-2 placeholder-glow" onClick={() => onClick()}>
                <div className="card-body" style={{cursor: isClickable() ? "pointer" : undefined}}>
                    <h3 className="card-title">
                        <div className="row">
                            <div className="col">{getHeader()}</div>
                            <div className="col-auto">{props.header!.slice(1, props.header!.length).filter(header => header.isRight == "title").map((header, i) => getChild(header, i))}</div>
                        </div>
                    </h3>
                    <div className="row text-secondary ">
                        <div className="col">
                            {getA() ?? props.header!.slice(1, props.header!.length).filter(header => header.isRight == null || header.isRight == "none").map((header, i) => getChild(header, i))}
                        </div>
                        <div className="col-auto">
                            {getA() ?? props.header!.slice(1, props.header!.length).filter(header => header.isRight == "normal").map((header, i) => getChild(header, i))}
                        </div>
                    </div>
                </div>
            </div>
        </LongPressable>
    )
}
