import {Expose} from "class-transformer";
import axios from "axios";
import {ProcessResponseError} from "API";
import {GetAPIURL} from "../Define";
import {isNullorWhiteSpace, serializeForm} from "../../../utils/HSUtils";
import BoardData from "./BoardData";

export default class BoardForm
{
    @Expose() public Title: string;
    @Expose() public Contents: string;
    public FormAttachFile: File[];
    public FormAttachFileDelete: string[];
    public FormAttachSort: string[];

    public Check()
    {
        if (isNullorWhiteSpace(this.Title)) return "제목은 비어있을 수 없습니다";
        if (isNullorWhiteSpace(this.Contents)) return "내용은 비어있을 수 없습니다";
        return null;
    }
    
    /**
     * 
     * @param {string} BoardKind
     * @param {string} BoardID
     * @returns {Promise<number | null>} 게시글 번호
     * @constructor
     */
    public Submit(BoardKind: string, BoardID?: string): Promise<string>
    {
        const send = axios({
            method: "POST",
            url: GetAPIURL(BoardKind, BoardID),
            headers: { 'Content-Type': 'multipart/form-data' },
            data: this,
            // 폼에서 [] 없애기
            formSerializer: { indexes: null },
        });

        return new Promise<any>((resolve, reject) =>
        {
            send.catch((error: Error) => reject(ProcessResponseError(error, true, true)));
            send.then((response) => resolve(response.data));
        });
    }

    public static FromData(data: BoardData)
    {
        const form = new BoardForm();
        form.Title = data.Title;
        form.Contents = data.Contents;
        return form;
    }

    public static FromForm(form: FormData)
    {
        const data = serializeForm(form);
        const doc = new BoardForm();
        doc.Title = data.Title;
        doc.Contents = data.Contents;
        doc.FormAttachFile = data.FormAttachFile;
        doc.FormAttachFileDelete = data.FormAttachFileDelete;
        doc.FormAttachSort = data.FormAttachSort;
        return doc;
    }
}