import {Route} from "react-router-dom";
import React from "react";
import {
    ID, MENU_MAT, MENU_MAT_ORDER, MENU_MAT_REQUEST,
    MENU_VCD, MENU_VCD_ACCOUNT,
    MENU_VCD_CARD, MENU_VCD_COMMON,
    MENU_VCD_TAXBILL
} from "../DefineAccount";
import {redirectModulePath} from "../../ModuleInfo";
import {DEFAULT_MODULE_PATH} from "../info/account";
import DocumentListPage from "../page/document/DocumentListPage";
import {LIST_MY_CLASS} from "../Define";

const AccountingRouter = () =>
{
    return (
        <Route path={ID} element={redirectModulePath(ID, DEFAULT_MODULE_PATH)}>
            <Route path={MENU_VCD}>
                <Route path={""} element={<DocumentListPage kind={LIST_MY_CLASS} myClass={"5010,5020,5030"}/>}/>
                <Route path={MENU_VCD_COMMON} element={<DocumentListPage kind={LIST_MY_CLASS} myClass={"5010,5030"}/>}/>
                <Route path={MENU_VCD_ACCOUNT} element={<DocumentListPage kind={LIST_MY_CLASS} myClass={"5010"}/>}/>
                <Route path={MENU_VCD_CARD} element={<DocumentListPage kind={LIST_MY_CLASS} myClass={"5020"}/>}/>
                <Route path={MENU_VCD_TAXBILL} element={<DocumentListPage kind={LIST_MY_CLASS} myClass={"5030"}/>}/>
            </Route>
            <Route path={MENU_MAT}>
                <Route path={MENU_MAT_REQUEST} element={<DocumentListPage kind={LIST_MY_CLASS} myClass={"1300"}/>}/>
                <Route path={MENU_MAT_ORDER} element={<DocumentListPage kind={LIST_MY_CLASS} myClass={"1310"}/>}/>
            </Route>
        </Route>
    )
}

export default AccountingRouter;