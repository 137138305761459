import {Async} from "react-async";
import React, {CSSProperties} from "react";
import fileLoadingIcon from "../image/file-loading.gif";
import fileError from "../image/file-error.png";
import {
    IconFileDigit,
    IconFileMusic, IconFileSpreadsheet,
    IconFileText,
    IconFileTypePdf,
    IconFileTypePpt,
    IconFileWord,
    IconFileZip,
    IconVideo
} from "@tabler/icons-react";
import FileAttach from "../Data/FileAttach";


const imgStyle: CSSProperties = {
    display: "block",
    width: "auto",
    height: "100%",
    margin: "0 auto"
};
const loadingStyle: CSSProperties = {
    height: "40px",
    margin: "30px auto 0"
};


type ThumbProps = { file: FileAttach }
/*
type ThumbState = { data: string | null }
class Thumb extends Component<ThumbProps, ThumbState>
{
    constructor(props: ThumbProps)
    {
        super(props);
        this.state = { data: null };
    }

    componentDidMount() {
        this.props.file.readData().then(data => this.setState({ data: data ?? ""}));
    }

    render() {
        return this.props.file.type.indexOf("image/") < 0 ? <img src={fileDefaultIcon} style={imgStyle}/> :
            (this.state.data == null ?
                <img src={fileLoadingIcon} style={imgStyle}/> :
                <img src={this.state.data == "" ? fileError : this.state.data} style={imgStyle}/>
            );
    }
}
*/

const Thumb = ({file}: ThumbProps) => {
    switch (file.kind) {
        case 'Image':
            return (
                <Async promiseFn={() => file.readData()}>
                    <Async.Pending><img src={fileLoadingIcon} style={loadingStyle}/></Async.Pending>
                    <Async.Fulfilled>{(data: string | null) => <img src={data ?? fileError}
                                                                    style={imgStyle}/>}</Async.Fulfilled>
                </Async>);
        case 'PDF':
            return <IconFileTypePdf size={64}/>;
        case 'Archive':
            return <IconFileZip size={64}/>;
        case 'Text':
            return <IconFileText size={64}/>;
        case 'Video':
            return <IconVideo size={64}/>;
        case 'Audio':
            return <IconFileMusic size={64}/>;
        case 'Word':
            return <IconFileWord size={64}/>;
        case 'PowerPoint':
            return <IconFileTypePpt size={64}/>;
        case 'SpreadSheet':
            return <IconFileSpreadsheet size={64}/>;
        default:
            console.log(`Type: ${file.type} / Kind: ${file.kind}`);
            return <IconFileDigit size={64}/>;
    }
}

export default Thumb;