import AttachData from "data/API/IO/AttachData";
import {randomString} from "@hskernel/hs-utils";
import {readFile} from "utils/HSUtils";
import {FileKind, getFileKind} from "../index";


export default class FileAttach
{
    //private fileRef = createRef<HTMLInputElement>();
    constructor(file: File | null, attach: AttachData | null)
    {
        if (file != null)
        {
            this.file = file;
            this.name = file.name;
            this.type = file.type;
            this.size = file.size;
            this.kind = getFileKind(file.type);
        }
        else if (attach != null)
        {
            this.attach = attach;
            this.name = attach.Name;
            this.size = attach.Size;
            this.type = attach.Type;
            this.kind = getFileKind(attach.Type);
        }
    }

    public file: File | null;
    public attach: AttachData | null;

    public name: string;
    public size: number;
    public type: string;
    public kind: FileKind;

    public id: string = randomString(10);

    public setParam(Param: string | null)
    {
        if (this.attach != null) this.attach.setParam(Param);
        return this;
    }

    //public getIcon(){}

    private data: string | null = null;

    public async readData(): Promise<string | null> {
        if (this.data != null) return this.data;
        else
        {
            if (this.file)
            {
                try
                {
                    this.data = await readFile(this.file);
                    return this.data;
                }
                catch (error: any) { console.log('FileRead Error!!: ', error); }
            }
            else if (this.attach)
            {
                //return this.attach.getURL();
                try
                {
                    this.data = await this.attach.getDataAsync();
                    return this.data;
                }
                catch (e) { console.log(e); }
            }

            return null;
        }
    }
}