import React, {CSSProperties, useState, useEffect, useRef, forwardRef, ReactNode} from 'react'
import styles from './index.module.css';
import {useDropzone} from 'react-dropzone'
import {randomKey, readFile} from "utils/HSUtils";
import AttachData from "data/API/IO/AttachData";
import {FileViewerDialog, FileViewerType} from "../FileViewerControl";
import FileAttach from "./Data/FileAttach";
import FileAttachItem from "./Components/FileAttachItem";
//import {DropResult, ResponderProvided} from "react-beautiful-dnd";

/*
type FileAttachControl = {
    name?: string,
    nameDelete?: string
}
*/
type FileAttachControlProps = {
    attachs?: AttachData[],
    name?: string,
    nameDelete?: string,
    /**
     * 정렬 이름
     */
    nameSort?: string,
    isViewer?: boolean,
    /**
     * form 모드로 토큰값을 같이 보냅니다
     */
    authToken?: string | null,
}

const FileAttachControl = ({name, nameDelete, attachs, isViewer, authToken, ...props}: FileAttachControlProps) =>
{
    //const Navigate = useNavigate();
    const [files, setFiles] = useState<FileAttach[]>([]);
    const [deleteFile, setDeleteFile] = useState<string[]>([]);
    const preview = useRef<FileViewerDialog | null>(null);

    const thumbsContainerStyle: CSSProperties = {
        marginTop: 16,

        /*display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",*/
    };
    const thumbsContainerStyle1: CSSProperties = {
        marginTop: 16,
    };

    const deleteAttach = (index: number) =>
    {
        const file = files[index];
        if (file.attach != null) setDeleteFile(deleteFile.concat(file.attach.Key));
        setFiles(files.remove(index));
    }

    const onDelete = (index: number, fileName: string) =>
    {
        if (confirm(`${fileName} 파일을 삭제하시겠습니까?`)) deleteAttach(index);
    }

    /*
    function onDragDropEnd(result: DropResult, provided: ResponderProvided)
    {
        if (isDebugMode()) console.log(result);

        if (!result.destination) return;
        const items = [...files];
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setFiles(items);
    }
     */

    useEffect(() => {
        if (attachs)
        {
            const files = attachs.map(attach => new FileAttach(null, attach));
            /*
            for (let i = 0; i < 20; i++)
            {
                files[i] = new FileAttach(null, attachs[0]);
                files[i].name = i.toString();
            }
             */
            setFiles(files);
        }
    }, []);

    return (
        <>
            <FileViewerDialog ref={preview}/>
            <h3 className="card-title">일반첨부파일(<span>{files.length}</span>개)</h3>
            <div style={isViewer ? {overflow: "hidden"} : {overflowY: "scroll", width: "calc(100vw - 90px)"}}>
                {name == null ?
                    (attachs == null || attachs.length == 0 ? "첨부파일이 존재하지 않습니다" : "") :
                    <DropZone onAdd={(data) => setFiles(files.concat(data))}/>
                }
                <div>
                    {deleteFile.map((token) =>
                        <input key={randomKey()} type="hidden" name={nameDelete === null ? "FormAttachFileDelete[]" : `${nameDelete}`} value={token}/>)}
                </div>
                <div style={thumbsContainerStyle} className={styles.attach_file_container}>
                    {props.nameSort != null ?
                        <DragAndDrop items={files} onDragEnd={setFiles} render={(file, index) => <FileAttachItem key={randomKey(index)} file={file} index={index} name={name} nameSort={props.nameSort} authToken={authToken} preview={preview} onDelete={onDelete}/>}/> :
                        files.map((file, index) => <FileAttachItem key={randomKey(index)} file={file} index={index} name={name} authToken={authToken} preview={preview} onDelete={onDelete}/>)
                    }
                </div>
            </div>
        </>
    )
}
export default FileAttachControl;

type DropZoneProps = { onAdd: (data: FileAttach[]) => void }
const DropZone = ({onAdd}: DropZoneProps) => {
    const {getRootProps, getInputProps} = useDropzone({
        //TODO: 나중에 서버에서 사용가능한 확장자 제공하기
        //accept: {"*/*": []},
        onDrop: (acceptedFiles) => onAdd(acceptedFiles.map(file => new FileAttach(file, null)))
    });

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p style={{cursor: "pointer"}}>이곳을 클릭하여 파일을 첨부하십시오.</p>
        </div>
    )
}


export type FileKind = "Other" | "PDF" | "Image" | "Archive" | "Text" | "Video" | "Audio" | "Word" | "PowerPoint" | "SpreadSheet";
export function getFileKind(mime: string): FileKind
{
    const checkType = (includeType: string) => mime.indexOf(includeType) >= 0;

    if (checkType('image/')) return 'Image';
    else if (checkType('/pdf')) return 'PDF';
    else if (checkType('video/')) return 'Video';
    else if (checkType('audio/')) return 'Audio';
    else if (checkType('/zip') || checkType('/x-tar') || checkType('/gzip') || checkType('/vnd.rar')) return 'Archive';
    else if (checkType('text/') || checkType('/json')) return 'Text';
    else if (checkType('/msword') || checkType('/vnd.ms-word') || checkType('/vnd.openxmlformats-officedocument.wordprocessingml.document')) return 'Word';
    else if (checkType('/msexcel') || checkType('/vnd.ms-excel') || checkType('/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || checkType('/ms-excel')) return 'SpreadSheet';
    else if (checkType('/vnd.ms-powerpoint') || checkType('/vnd.openxmlformats-officedocument.presentationml.presentation')) return 'PowerPoint';
    else return 'Other';
}

/*

type PreviewKind = "PDF" | "IMAGE";
type PreviewState = {
    kind: PreviewKind,
    attach: AttachData
}
*/

type DragAndDropProps<T> = {
    items: T[];
    render(item: T, index: number): ReactNode;
    onDragEnd(newItems: T[]): void;
}
export function DragAndDrop<T>({ items, render, onDragEnd }: DragAndDropProps<T>) {
    const [temporaryItems, setTemporaryItems] = useState<T[]>();
    const [draggedItem, setDragedItem] = useState<T>();

    const renderedItems = temporaryItems || items;

    return (
        <>
            {renderedItems.map((item, index) => (
                <div
                    key={index}
                    style={{ cursor: "grab", ...(item === draggedItem ? { opacity: "0.1", /*border: "2px dashed grey"*/ } : {})}}
                    draggable
                    onDragStart={() => setDragedItem(item)}
                    onDragOver={(e) => {
                        e.preventDefault();
                        if (!draggedItem || draggedItem === item) {
                            return;
                        }
                        const currentIndex = renderedItems.indexOf(draggedItem);
                        const targetIndex = renderedItems.indexOf(item);

                        if (currentIndex !== -1 && targetIndex !== -1) {
                            const newItems = [...renderedItems];
                            newItems.splice(currentIndex, 1);
                            newItems.splice(targetIndex, 0, draggedItem);
                            setTemporaryItems(newItems);
                        }
                    }}
                    onDragEnd={() => {
                        onDragEnd(renderedItems);
                        setDragedItem(undefined);
                        setTemporaryItems(undefined);
                    }}>
                    {render(item, index)}
                </div>
            ))}
        </>
    );
}