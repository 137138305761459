import ModuleInfo, {AdminMenuKind} from "modules/ModuleInfo";

import {
    ID,
    MENU_MAT,
    MENU_MAT_ORDER,
    MENU_MAT_REQUEST,
    MENU_VCD, MENU_VCD_ACCOUNT,
    MENU_VCD_CARD, MENU_VCD_COMMON,
    MENU_VCD_TAXBILL,
} from "../DefineAccount";
import {PathMakerMulti} from "@hskernel/hs-utils";
import {IconFileDollar} from "@tabler/icons-react";
import iconDefaultDark from "../resource/menu/menu_default_dark.png";
import iconDefault from "../resource/menu/menu_default.png";
import React from "react";

export const DEFAULT_MODULE_PATH = PathMakerMulti(ID, MENU_VCD);
const Info: ModuleInfo =
{
    id: ID,
    name: "Purchase",
    title: "회계&구매",
    onRenderIcon: (darkMode, isSmall) => <IconFileDollar/>,
    admin: AdminMenuKind.All,
    category: [
        {
            id: 'vcd',
            title: "지출결의관리",
            path: MENU_VCD,
            open: true,
            menu: [
                {
                    id: '',
                    title: "지출결의서 전제",
                    path: '',
                    popup: false,
                    shortcut: () => 'show',
                    onRenderIcon: (darkMode: boolean) => darkMode ? iconDefaultDark : iconDefault,
                },
                {
                    id: 'common',
                    title: "지출결의서 현황",
                    path: MENU_VCD_COMMON,
                    popup: false,
                    shortcut: () => 'show',
                    onRenderIcon: (darkMode: boolean) => darkMode ? iconDefaultDark : iconDefault,
                },
                {
                    id: 'account',
                    title: "지출결의서",
                    path: MENU_VCD_ACCOUNT,
                    popup: false,
                    shortcut: () => 'show',
                    onRenderIcon: (darkMode: boolean) => darkMode ? iconDefaultDark : iconDefault,
                },
                {
                    id: 'card',
                    title: "카드사용",
                    path: MENU_VCD_CARD,
                    popup: false,
                    shortcut: () => 'show',
                    onRenderIcon: (darkMode: boolean) => darkMode ? iconDefaultDark : iconDefault,
                },
                {
                    id: 'taxbill',
                    title: "세금계산서",
                    path: MENU_VCD_TAXBILL,
                    popup: false,
                    shortcut: () => 'show',
                    onRenderIcon: (darkMode: boolean) => darkMode ? iconDefaultDark : iconDefault,
                },
            ]
        },
        {
            id: 'mat',
            title: "자재품의관리",
            path: MENU_MAT,
            open: true,
            menu: [
                {
                    id: 'request',
                    title: "구매요청서",
                    path: MENU_MAT_REQUEST,
                    popup: false,
                    shortcut: () => 'hide',
                    onRenderIcon: (darkMode: boolean) => darkMode ? iconDefaultDark : iconDefault,
                },
                {
                    id: 'order',
                    title: "구매발주서",
                    path: MENU_MAT_ORDER,
                    popup: false,
                    shortcut: () => 'hide',
                    onRenderIcon: (darkMode: boolean) => darkMode ? iconDefaultDark : iconDefault,
                }
            ]
        }
    ]
}

export default Info;
