import React, {Component, createRef, useEffect, useState} from "react";
import UserDataBase, {UserSearchData, UserSearchKinds} from "data/API/User/UserDataBase";
import {
    APPROVER_KIND_AGREE_TEXT,
    APPROVER_KIND_APPROVAL_TEXT,
    APPROVER_KIND_EXEC_TEXT,
    APPROVER_KIND_REF_TEXT
} from "../../data/ApprovalDocumentBase";
import ImplementTable from "./internal/ImplementTable";
import UserListSelect from "common/control/selector/internal/UserListSelect";
import {ISelector, ApprovalSelectorData} from "common/control/selector/internal/ISelector";
import AuthData from "Auth";
import DepartmentPick from "common/control/DepartmentPickControl";
import SearchControl, {
    SearchOptionEvent,
    getSearchOptionValueFromEvent,
    SearchControlClass
} from "common/control/SearchControl";
import {
    APPROVER_KIND_AGREE,
    APPROVER_KIND_APPROVAL,
    APPROVER_KIND_EXEC,
    APPROVER_KIND_REF
} from "../../data/ApprovalStatus";
import UserListSelectEx from "common/control/selector/internal/UserListSelectEx";
import ApprovalLineExtra from "../../type/ApprovalLineExtra";
import {APPROVER_DIV_APPROVAL, APPROVER_DIV_EXEC, APPROVER_DIV_REF, MAX_APPROVALS} from "../../Define";
import ApprovalLineData from "../../data/ApprovalLineData";
import {printError} from "Define";
import {randomKey} from "@hskernel/hs-utils";
import ApprovalLineDocuData from "../../data/ApprovalLineDocuData";
import ApprovalLineFormBase from "../../data/ApprovalLineFormBase";


export class ApprovalLineMultiData
{
    /**
     *
     * @type {string}
     */
    public kind: string;
    public Approver: ApprovalSelectorData<ApprovalLineExtra>[] = [];
    public UserRef: ApprovalSelectorData<any>[] = [];
    public UserExec: ApprovalSelectorData<any>[] = [];
}

type ApprovalLineSelectProps = {
    auth: AuthData,
    initData?: ApprovalLineMultiData,
    maxApprovals?: number,
    showFinal?: boolean,
    docuClass?: string,
    onBusy?: (busy: boolean) => void,
    isLineSetting?:boolean
}

type ApprovalLineMultiSelectState = {
    busy: boolean,
    data?: ApprovalLineMultiData,
}

/**
 *
 * @param {string} modalType
 * @param {string} deptCode
 * @param {ApprovalLineMultiData | null} initData
 * @param {(ApprovalLineMultiData) => void} onSave
 * @returns {JSX.Element}
 */

export class ApprovalLineMultiSelect extends Component<ApprovalLineSelectProps, ApprovalLineMultiSelectState>
{
    private userList = createRef<MultiSelectListUser>();
    private userAdd = createRef<MultiSelectAddUser>();

    constructor(props: ApprovalLineSelectProps) {
        super(props);

        this.state = {
            busy: false
        }

        this.onLineSelect = this.onLineSelect.bind(this);
        this.onSaveDefault = this.onSaveDefault.bind(this);
        this.onLoadDefault = this.onLoadDefault.bind(this);
    }

    getData(): ApprovalLineMultiData { return this.userAdd.current == null ? new ApprovalLineMultiData() : this.userAdd.current.getData(); }
    private onLineSelect(line: ApprovalLineData)
    {
        if(this.userAdd.current != null)
        {
            const data = ConvertApprovalLineData(line);
            if(data != null) this.userAdd.current.setData(data);
        }
    }

    private onSaveDefault()
    {
        if(this.props.docuClass != null)
        {
            this.setState({...this.state, busy: true});
            if(this.props.onBusy != null) this.props.onBusy(true);

            const form = ApprovalLineFormBase.FromMultiData(this.getData());
            form.SaveAsync(this.props.docuClass)
                .then(() => alert("저장되었습니다"))
                .catch(e => printError(e))
                .finally(() =>
                {
                    this.setState({...this.state, busy: false});
                    if(this.props.onBusy != null) this.props.onBusy(false);
                });
            /**/
        }
    }
    private onLoadDefault()
    {
        if(this.props.docuClass != null && confirm("정말 기본값을 불러오시겠습니까?\n기존의 결재라인은 모두 삭제됩니다"))
        {
            this.setState({...this.state, busy: true});
            if(this.props.onBusy != null) this.props.onBusy(true);

            ApprovalLineDocuData.GetAsync(this.props.docuClass)
                .then(data =>
                {
                    const _data = ConvertApprovalLineData(data ?? undefined);
                    if(this.userAdd.current != null) this.userAdd.current.setData(_data ?? new ApprovalLineMultiData())
                })
                .catch(e => printError(e))
                .finally(() =>
                {
                    this.setState({...this.state, busy: false});
                    if(this.props.onBusy != null) this.props.onBusy(false);
                });
        }
    }

    render()
    {
        const btnStyle = {
            width:"120px",
            heightL:"50px",
            border:"1px solid #eee",
            padding:"6px",
            borderRadius:"4px",
        }
        return (
            <div className="card-body" style={{overflowY:"scroll",height:`${this.props.isLineSetting?"calc(100vh - 300px)":"calc(100vh - 170px)"}`}}>
                <div className="row">
                    <div className="col-sm-12 col-lg-4 mb-3">
                        <div className="card">
                            <div className="card-header">
                                조직도
                            </div>
                            <div className="card-body">
                                <DepartmentPick deptCode={this.props.auth.user.DeptCode!} onSelect={(dept) => { if (this.userList.current != null) this.userList.current.setDeptCodeAsync(dept.DepartmentCode).then() } }/>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-8">
                        <div className="card">
                            <div className="card-header">
                                <div className="col">결재라인선택</div>
                                &nbsp;
                                <div className="col-auto">
                                {this.props.docuClass == null ? "" :
                                    <>
                                        <button className="bg-green-lt" style={btnStyle} disabled={this.state.busy} onClick={this.onSaveDefault}>기본값 저장</button>
                                        &nbsp;
                                        <button className="bg-muted-lt" style={btnStyle} disabled={this.state.busy} onClick={this.onLoadDefault}>기본값 불러오기</button>
                                    </>
                                }
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <MultiSelectListUser ref={this.userList} LoginID={this.props.auth.user.LoginID} onSelectLine={this.onLineSelect} onSelect={(kind, users) => this.userAdd.current?.addData(kind, users)}/>
                                    <p style={{padding: "10px"}}></p>
                                    <MultiSelectAddUser ref={this.userAdd} initData={this.props.initData} company={this.props.auth.company.Name} max={this.props.maxApprovals ?? MAX_APPROVALS} showFinal={this.props.showFinal}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ApprovalLineMultiSelect;

export function ConvertApprovalLineData(data?: ApprovalLineData | ApprovalLineDocuData)
{
    if(data == null) return undefined;

    const multi = new ApprovalLineMultiData();
    multi.kind = APPROVER_DIV_APPROVAL;
    multi.Approver = data.Approval.map(v => new ApprovalSelectorData<ApprovalLineExtra>(v, v.Division, false, {final: v.IsFinal}));
    multi.UserRef = data.RefUser.map(v => new ApprovalSelectorData<any>(v, APPROVER_DIV_REF));
    multi.UserExec = data.ExecUser.map(v => new ApprovalSelectorData<any>(v, APPROVER_DIV_EXEC));
    return multi;
}

type MultiSelectUserProps = {
    onSelect: (kind: string, users: UserDataBase[]) => void,
    onSelectLine: (line: ApprovalLineData) => void;
    deptCode?: string,
    LoginID: string,
}
type MultiSelectUserState = {
    search?: UserSearchData,
    deptCode?: string,
    showAll: boolean,
}
class MultiSelectListUser extends Component<MultiSelectUserProps, MultiSelectUserState>
{
    private selector = createRef<UserListSelectEx>();
    private option = [UserSearchKinds[0]];

    constructor(props: any) {
        super(props);

        this.state = {
            showAll: false
        }

        this.onSearchAsync = this.onSearchAsync.bind(this);
        this.searchAsync = this.searchAsync.bind(this);
    }

    private onSelect(Kind: string)
    {
        if (this.selector.current != null)
        {
            const users = this.selector.current.Save();
            this.props.onSelect(Kind, users);
        }
    }

    public async setDeptCodeAsync(deptCode: string, showAll?: boolean)
    {
        if (this.selector.current != null)
        {
            const _showAll = showAll ?? this.state.showAll;
            await new Promise<void>(resolve => this.setState({...this.state, search: undefined, deptCode: deptCode, showAll: _showAll}, resolve));
            await this.selector.current.setDeptCodeAsync(deptCode, _showAll ? undefined : true);
        }
    }

    private onSearchAsync(keyword: string | null, optionEvents: SearchOptionEvent[])
    {
        const search = UserDataBase.toSearchData(keyword, getSearchOptionValueFromEvent(optionEvents));
        return this.searchAsync(search);
    }
    private async onAllCheckedAsync(checked: boolean)
    {
        if (this.state.search != null) await this.searchAsync(this.state.search, checked);
        else if (this.state.deptCode != null) await this.setDeptCodeAsync(this.state.deptCode, checked);
    }
    private async searchAsync(search?: UserSearchData, showAll?: boolean)
    {
        if (this.selector.current != null)
        {
            const _search = search ?? this.state.search;
            const _showAll = showAll ?? this.state.showAll;
            if (_search != null) _search.isEmployee = _showAll ? null : true;
            await new Promise<void>(resolve => this.setState({...this.state, search: _search, deptCode: undefined, showAll: _showAll}, resolve));
            await this.selector.current.setSearchAsync(_search);
        }
    }

    private id_check_all = randomKey("chk");
    render()
    {
        return (
            <>
                <div>
                    <label className="form-check">
                        <input type="checkbox" className="form-check-input" id={this.id_check_all} onChange={e => this.onAllCheckedAsync(e.target.checked).then()}/>
                        <label className="form-check-label" htmlFor={this.id_check_all}>퇴사자 포함</label>
                    </label>
                </div>
                <div className="col-lg-6">
                    <SearchControl onSearch={this.onSearchAsync} options={this.option} placeholder="검색어 입력"/>
                </div>
                <div className="col-lg-6">
                    <ApprovalLineOption LoginID={this.props.LoginID} onSelect={this.props.onSelectLine}/>
                </div>
                <div className="row mb-3 mt-3">
                    <div className="col-6 col-sm-4 col-md-3 col-xl">
                        <button type="button" className="btn btn-outline-mute w-100" onClick={() => this.onSelect(APPROVER_KIND_APPROVAL)}>
                            {APPROVER_KIND_APPROVAL_TEXT}
                        </button>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3 col-xl">
                        <button type="button" className="btn btn-outline-mute w-100" onClick={() => this.onSelect(APPROVER_KIND_AGREE)}>
                            {APPROVER_KIND_AGREE_TEXT}
                        </button>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3 col-xl">
                        <button type="button" className="btn btn-outline-mute w-100" onClick={() => this.onSelect(APPROVER_KIND_REF)}>
                            {APPROVER_KIND_REF_TEXT}
                        </button>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3 col-xl">
                        <button type="button" className="btn btn-outline-mute w-100" onClick={() => this.onSelect(APPROVER_KIND_EXEC)}>
                            {APPROVER_KIND_EXEC_TEXT}
                        </button>
                    </div>
                </div>
                <UserListSelectEx ref={this.selector} deptCode={this.props.deptCode} showCheck={true}/>
            </>
        )
    }
}


type MultiSelectAddUserProps = {
    initData?: ApprovalLineMultiData,
    company?: string,
    max?: number,
    showFinal?: boolean,
}
type MultiSelectAddUserState = {
    activeKind: string,
    data: ApprovalLineMultiData,
}
class MultiSelectAddUser extends Component<MultiSelectAddUserProps, MultiSelectAddUserState>
{
    private readonly defaultKind = APPROVER_KIND_APPROVAL;

    private cmpApprover = createRef<ImplementTable>();
    private cmpUserRef = createRef<UserListSelect>();
    private cmpUserExec = createRef<UserListSelect>();

    constructor(props: MultiSelectAddUserProps)
    {
        super(props);

        this.setActiveKind = this.setActiveKind.bind(this);
        this.getData = this.getData.bind(this);
        this._addData = this._addData.bind(this);
        this.addData = this.addData.bind(this);

        let activeKind = this.defaultKind;
        let data = props.initData;

        if(data == null)
        {
            data = new ApprovalLineMultiData();
            data.kind = activeKind;
        }
        else activeKind = data.kind;

        this.state = {
            activeKind: activeKind,
            data: data
        }
    }

    componentDidMount()
    {
        if(this.props.initData != null)
        {
            if(this.props.initData.Approver) this._addData(APPROVER_KIND_APPROVAL, this.props.initData.Approver);
            if(this.props.initData.UserExec) this._addData(APPROVER_KIND_EXEC, this.props.initData.UserExec);
            if(this.props.initData.UserRef) this._addData(APPROVER_KIND_REF, this.props.initData.UserRef);
        }
    }

    private readonly setActiveKind = (kind: string) => this.setState({...this.state, activeKind: kind});
    getData(): ApprovalLineMultiData
    {
        const getData = (selector: ISelector<any, any> | null) => selector == null ? [] : selector.getData();

        const data = new ApprovalLineMultiData();
        data.kind = this.props.initData?.kind ?? this.defaultKind;
        data.Approver = getData(this.cmpApprover.current);
        data.UserRef = getData(this.cmpUserRef.current);
        data.UserExec = getData(this.cmpUserExec.current);
        return data;
    }
    setData(data: ApprovalLineMultiData)
    {
        if(this.cmpApprover.current != null) this.cmpApprover.current.clearData();
        if(this.cmpUserExec.current != null) this.cmpUserExec.current.clearData();
        if(this.cmpUserRef.current != null) this.cmpUserRef.current.clearData();
        this._addData(APPROVER_KIND_APPROVAL, data.Approver);
        this._addData(APPROVER_KIND_REF, data.UserRef);
        this._addData(APPROVER_KIND_EXEC, data.UserExec);
    }
    addData(kind: string, users: UserDataBase[])
    {
        const datas = users.map(user => new ApprovalSelectorData<ApprovalLineExtra>(user, kind));
        this._addData(kind, datas);

        this.setActiveKind(kind);
    }
    private _addData(kind: string, datas: ApprovalSelectorData<ApprovalLineExtra>[])
    {
        if(kind == APPROVER_KIND_APPROVAL || kind == APPROVER_KIND_AGREE) this.cmpApprover.current?.addData(datas);
        else if(kind == APPROVER_KIND_EXEC) this.cmpUserExec.current?.addData(datas);
        else if(kind == APPROVER_KIND_REF) this.cmpUserRef.current?.addData(datas);
    }
    render() {
        return (
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-header">
                            <ul className="nav nav-tabs card-header-tabs nav-fill" data-bs-toggle="tabs" role="tablist" style={{cursor: "pointer"}}>
                                <li className="nav-item" role="presentation" onClick={()=>this.setActiveKind(APPROVER_KIND_APPROVAL)}>
                                    <a className={this.state.activeKind === APPROVER_KIND_APPROVAL || this.state.activeKind === APPROVER_KIND_AGREE ? 'nav-link active':'nav-link'} role="tab">
                                        {APPROVER_KIND_APPROVAL_TEXT}
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation" onClick={()=>this.setActiveKind(APPROVER_KIND_REF)}>
                                    <a className={this.state.activeKind === APPROVER_KIND_REF ? 'nav-link active':'nav-link'} role="tab">
                                        {APPROVER_KIND_REF_TEXT}
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation" onClick={()=>this.setActiveKind(APPROVER_KIND_EXEC)}>
                                    <a className={this.state.activeKind === APPROVER_KIND_EXEC ? 'nav-link active':'nav-link'} role="tab">
                                        {APPROVER_KIND_EXEC_TEXT}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="card-body">
                            <div className="tab-content">
                                <div className={this.state.activeKind === APPROVER_KIND_APPROVAL  || this.state.activeKind === APPROVER_KIND_AGREE ?'tab-pane active show':'tab-pane'} id="tabs1" role="tabpanel">
                                    {<ImplementTable ref={this.cmpApprover} company={this.props.company} max={this.props.max} props={{showFinal: this.props.showFinal}}/>}
                                </div>
                                <div className={this.state.activeKind === APPROVER_KIND_REF ? 'tab-pane active show':'tab-pane'} id="tabs3" role="tabpanel">
                                    {<UserListSelect ref={this.cmpUserRef} company={this.props.company} props={undefined}/>}
                                </div>
                                <div className={this.state.activeKind === APPROVER_KIND_EXEC ? 'tab-pane active show':'tab-pane'} id="tabs4" role="tabpanel">
                                    {<UserListSelect ref={this.cmpUserExec} company={this.props.company} props={undefined}/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

type ApprovalLineOptionProps = {
    LoginID: string,
    onSelect: (line: ApprovalLineData) => void
}
const ApprovalLineOption = (props: ApprovalLineOptionProps) =>
{
    const [busy, setBusy] = useState(false);
    const [lines, setLines] = useState<ApprovalLineData[]>([]);

    useEffect(() => { Refresh().then(lines => setLines(lines)); }, []);

    async function Refresh()
    {
        setBusy(true);
        try { return await ApprovalLineData.GetApprovalLineListAsync(true) ?? [];}
        catch (ex){ printError(ex); return []; }
        finally { setBusy(false); }
    }

    function onSelect(index: string)
    {
        const idx = parseInt(index);
        if(idx > -1) props.onSelect(lines[idx]);
    }

    function getText(line: ApprovalLineData)
    {
        let title = line.toString();
        if (line.Writer.LoginID != props.LoginID) title += ` (${line.Writer.toString()})`;
        return title;
    }
    return (
        <select className="form-select" onChange={(e) => onSelect(e.currentTarget.value)}>
            <option value="-1" disabled={busy}>{busy ? "(불러오는 중...)" : "결재라인선택"}</option>
            {
                lines.map((line, i) => <option key={randomKey(i)} value={i}>{getText(line)}</option>)
            }
        </select>
    )
}