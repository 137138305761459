import {PathMaker} from "../utils/HSUtils";
import {isDebugMode, URL_FRONT_MODULE} from "../Define";
import {Navigate, Outlet} from "react-router-dom";
import React from "react";
import AlertData from "../data/API/Alert/AlertData";
import ModuleMenuProperty from "../data/API/Module/Menu/ModuleMenuProperty";
import ModuleMenuData, {DisplayType, ModuleMenuSubData} from "../data/API/Module/Menu/ModuleMenuData";
import {isEmptyString} from "@hskernel/hs-utils";

export const getModulePath = (path: string) => PathMaker(URL_FRONT_MODULE, path);
export const checkModuleKindMatch = (admin: boolean, kind?: AdminMenuKind): boolean =>
{
    let show;
    if(admin) show = kind != AdminMenuKind.UserOnly;
    else show = kind != AdminMenuKind.AdminOnly;
    return show;
}

export const isMenuDisplay = (MenuDisplay: DisplayType) => MenuDisplay == "Show";
export function getMenuDisplayByPath(property: ModuleMenuProperty, ModuleID: string, MenuIDPath: string, isAdmin: boolean): DisplayType
{
    let Display: DisplayType = "None";
    const MenuRoot = property.GetMenuData(isAdmin);
    if(MenuRoot != null)
    {
        let Menu = MenuRoot[ModuleID];
        if(Menu != null && Menu.Display != "None")
        {
            if(!isEmptyString(MenuIDPath))
            {
                const ID = MenuIDPath.split('\\');
                for(let i = 0; i < ID.length; i++)
                {
                    Menu = Menu.Sub[ID[i]];
                    if(Menu == null) return Display;
                }
            }
            Display = Menu.Display;
        }
    }
    return Display;
}

/**
 *
 * @param {ModuleMenuProperty} menuProperty 메뉴 활성화 여부 프로퍼티
 * @param {string} moduleID 모듈 ID
 * @param {string} modulePathID 모듈 경로
 * @param {boolean | undefined} hide 숨김여부
 * @param {AdminMenuKind | undefined} adminKind ()
 * @param {boolean} admin 현재 관리자 여부입니다 (useAuth() 의 admin)
 * @returns {boolean}
 */
export function checkModuleMenu(menuProperty: ModuleMenuProperty, moduleID: string, modulePathID: string, hide: boolean | undefined, adminKind: AdminMenuKind | undefined, admin: boolean)
{
    const kindMatch = checkModuleKindMatch(admin, adminKind);
    const display = getMenuDisplayByPath(menuProperty, moduleID, modulePathID, admin);

    if(isDebugMode()) console.log(`Module: ${moduleID}, Path: ${modulePathID}, Admin: ${admin}, [Display: ${display} / KindMatch: ${kindMatch}]`);
    return !hide && kindMatch && isMenuDisplay(display);
}

/**
 * 현재경로하고 해당경로가 일치하면 리다이렉트 시킵니다 (라우터 전용)
 * @param matchPath
 * @param redirectPath
 */
export function redirectModulePath(matchPath: string, redirectPath: string)
{
    const path = getModulePath(matchPath);
    if(window.location.pathname == path) return <Navigate to={getModulePath(redirectPath)} replace={true}/>;
    else return <Outlet/>
}

/**
 * 현재경로하고 해당경로가 일치하면 해당 컴포넌트를 표시합니다 (라우터 전용)
 * @param matchPath
 * @param element
 */
export function elementModulePath(matchPath: string, element: React.ReactElement)
{
    const path = getModulePath(matchPath);
    console.log(window.location.pathname);
    console.log(path);
    if(window.location.pathname == path) return element;
    else return <Outlet/>
}

export enum AdminMenuKind{
    UserOnly = 1,
    AdminOnly = 2,
    All = 3,
}

//type ModuleProps = {}

/**
 * 모듈 정보
 */
type ModuleInfo =
{
    /**
     * 모듈 ID 입니다 (모듈 루트 경로로도 쓰입니다)
     */
    id: string,
    /**
     * 모듈 이름 입니다 (영어를 권장합니다)
     */
    name: string,
    /**
     * 모듈 제목입니다
     */
    title: string,
    /**
     * 모듈 아이콘 렌더링시 콜백
     */
    onRenderIcon: (darkMode: boolean, isSmall: boolean) => string | undefined | null | JSX.Element,
    /**
     * 카테고리
     */
    category: ModuleMenuCategory[],
    /**
     * 모듈 기본 경로
     */
    defaultPath?: (admin: boolean) => string | null,
    /**
     * 관리자 모드일때 활성화 여부 입니다
     */
    admin?: AdminMenuKind,
    /**
     * 메뉴 onRender 전 호출됩니다
     * @param {boolean} admin
     * @param {ModuleMenuSubData?} menus 메뉴 목록
     * @param {() => void} doRerender 호출시 새로고침 (전달용)
     */
    onRenderInit?: (admin: boolean, menus: ModuleMenuSubData | undefined, doRerenderAsync: () => Promise<void>) => void | Promise<void>,
    /**
     * 모듈 데이터 수신부 (알림 등...)
     * @param {string} data 데이터 입니다
     * @returns {boolean} 처리 성공 여부 입니다
     */
    onDataReceive?: (data: string) => boolean,
    /**
     * 알림 데이터 수신부
     * @param {AlertData} alert 알림 데이터 입니다
     * @returns {boolean} 메뉴 재 렌더링 여부 입니다
     */
    onNotiReceive?: (alert: AlertData) => boolean,
    /**
     * 카테고리 또는 메뉴 클릭했을 때
     * @param {ModuleMenuCategory} category
     * @param {ModuleMenu?} menu 해당 갑이 비어있으면 카테고리를 클릭함
     * @returns {boolean} 클릭 이벤트 처리여부 입니다
     */
    onClick?: (category: ModuleMenuCategory, menu?: ModuleMenu) => boolean,
}
export default ModuleInfo;

/**
 * 메뉴 카테고리
 */
export type ModuleMenuCategory = {
    /**
     * 카테고리 ID 입니다
     */
    id: string,
    /**
     * 카테고리 제목
     */
    title: string,
    /**
     * 펼쳐짐 여부
     */
    open: boolean,
    /**
     * 경로 (맨 앞에 '/' 를 붙일 시 모듈 루트 경로)
     */
    path?: string,
    /**
     * 숨기기 여부
     */
    hide?: boolean,
    /**
     * 관리자 모드일때 활성화 여부 입니다
     */
    admin?: AdminMenuKind,
    /**
     * 메뉴 목록
     */
    menu: ModuleMenu[],
    /**
     * 카테고리 데이터
     */
    data?: any,
}

export type ModuleMenu = {
    /**
     * 모듈 ID 입니다
     */
    id: string,
    /**
     * 메뉴 제목
     */
    title: string | JSX.Element,
    /**
     * 경로 (맨 앞에 '/' 를 붙일 시 모듈 루트 경로)
     */
    path: string,
    /**
     * 새 창으로 열릴지 여부
     */
    popup: boolean,
    /**
     * 숨기기 여부
     */
    hide?: boolean,
    /**
     * 바로가기 등록 종류 (none: (없음), show: 항상 보임, hide: 더보기(...) 버튼을 눌러야 보임)
     */
    shortcut?: (isAdminMode: boolean) => 'none' | 'show' | 'hide';
    /**
     * 메뉴 메뉴를 렌더링시 콜백 입니다
     * @param {ModuleMenu} menu
     * @returns {Promise<ModuleMenu>}
     */
    onRender?: (menu: ModuleMenu, isShortcut: boolean, admin: boolean) => ModuleMenu | Promise<ModuleMenu>
    /**
     * 메뉴 아이콘 렌더링시 콜백
     */
    onRenderIcon?: (darkMode: boolean, isSmall: boolean) => string | undefined | null | JSX.Element,
    /**
     * 텍스트대신 로딩 Placeholder 표시여부
     */
    placeholder?: boolean,
    /**
     * 관리자 모드일때 활성화 여부 입니다
     */
    admin?: AdminMenuKind,
    /**
     * 하위 메뉴
     */
    menu?: ModuleMenu[],
    /**
     * 메뉴 데이터
     */
    data?: any,
}
