import axios, {
    AxiosInstance,
    AxiosRequestHeaders,
    AxiosRequestTransformer,
    AxiosResponseTransformer,
} from "axios";
import {prototype} from "react-modal";


declare global {
    interface Document {
        getPath: () => string;
        getPathWindow: () => string;
        getName: () => string;
        getNameWindow: () => string;
        getDirectory: () => string;
        getDirectoryWindow: () => string;
        getRoot: () => string;
        getRootWindow: () => string;
        getDomain: () => string;
        getDomainWindow: () => string;
    }

    /*
    interface Object {
        toBoolean: () => boolean;
        toNumber: (defaultValue: number) => number;
        toFormData: () => FormData;
        getType: () => string;
    }

     */

    interface String {
        format: (...params: any[]) => string;
        remove: (startIndex: number, count?: number) => string;
        // @ts-ignore
        loop: (count: number) => string;
        // @ts-ignore
        pad: (pad: string, padLeft: boolean) => string;
        padNumber: (width: number) => string;
        replaceAll: (searchStr: string, replaceStr: string) => string;
        isEmailAddress: (str: string, pad: string, padLeft: boolean) => string;
        toLinebreakToBR: () => string;
        escapeHTML: () => string;
        makePath: (B: string, PathChar: string) => string;
        getFileName: () => string;
    }

    interface Number {
        toStringComma: () => string
    }

    interface Date {
        format: (format_str: string) => string;
        formatLegacy: (format_str: string) => string;
        isLeafYear: () => boolean;
        getTotalDays: () => number;
        addDays: (days: number) => Date;
        getDifferenceDay: (destDate: Date) => number;
    }

    interface Array<T> {
        joinEx: (separator?: string | null, offset?: number | null, count?: number | null) => string | null;
        remove: (index: number, count?: number) => Array<T>;
    }

    interface Element {
        loadExternal: (URL: string[], onComplete?: () => void, onError?: () => void) => void;
    }

    interface Document {
        getPath: () => string;
        getPathWindow: () => string;
        getName: () => string;
        getNameWindow: () => string;
        getDirectory: () => string;
        getDirectoryWindow: () => string;
        getRoot: () => string;
        getRootWindow: () => string;
        getDomain: () => string;
        getDomainWindow: () => string;
    }

    interface FormData {
        serialize: () => object
    }
}


//https://stackoverflow.com/a/55348259/7080663
export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export function StringFormat(...args: any[])
{
    // The string containing the format items (e.g. "{0}")
    // will and always has to be the first argument.
    let theString = args[0];

    // start with the second argument (i = 1)
    for (let i = 1; i < args.length; i++) {
        // "gm" = RegEx options for Global search (more than one instance)
        // and for Multiline search
        const regEx = new RegExp("\\{" + (i - 1) + "\\}", "gm");
        theString = theString.replace(regEx, args[i]);
    }

    return theString;
}
String.prototype.format = StringFormat;

export function replaceAll(str: string, searchStr: string, replaceStr: string) {
    return str.split(searchStr).join(replaceStr);
}

export function paddingNumber(value: number, width: number) {
    const _value = value + '';
    return _value.length >= width ? _value : new Array(width - _value.length + 1).join('0') + _value;
}

//window.location, URL에서 값 가져오기 -> https://hsmtree.wordpress.com/2015/04/16/window-location-url%EC%97%90%EC%84%9C-%EA%B0%92-%EA%B0%80%EC%A0%B8%EC%98%A4%EA%B8%B0/
Document.prototype.getPath = function() { return _getPath(); }
Document.prototype.getPathWindow = function() { return _getPath(); }
Document.prototype.getName = function() { return _getName(); }
Document.prototype.getNameWindow = function() { return _getName(); }
Document.prototype.getDirectory = function() { return _getDirectory(); }
Document.prototype.getDirectoryWindow = function() { return _getDirectory(); }
Document.prototype.getRoot = function() { return _getURLSubString(false); }
Document.prototype.getRootWindow = function() { return _getURLSubString(false); }
Document.prototype.getDomain = function() { return _getURLSubString(true); }
Document.prototype.getDomainWindow = function() { return _getURLSubString(true); }

function _getPath() { return window.location.pathname }
function _getName()
{
    const path = _getPath();
    return path.substring(path.lastIndexOf('/') + 1);
}
function _getDirectory()
{
    const path = _getPath();
    return path.substring(0, path.lastIndexOf('/'));
}
function _getURLSubString(isdomain: boolean)
{
    const url = _getPath();
    const index_start = url.indexOf('://') + 3;
    const index_end = url.indexOf('/', index_start);
    return url.substring(isdomain ? index_start : 0, index_end);
}

export function isEmpty(data: any): boolean { return data === undefined || data === null; }
export function isEmptyString(str: any): boolean { return isEmpty(str) || str === ""; }
export function isNullorWhiteSpace(str: any): boolean { return str == null || isEmptyString(str.toString().trim()); }

export function checkEmail(email: string): boolean { return isEmptyString(email) ? false : /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email); }

export function getDateFromISO8601(timestamp: string): string {
    const index = timestamp.indexOf("T");
    return timestamp.substring(0, (timestamp.length - index) + 1);
}
export function getDateFromDate(date: Date): string {
    return date.toISOString().slice(0, 10);
}

export function convertToInt(value: any, defaultValue: number): number
{
    const Value = parseInt(value);
    return Value.toString() === "NaN" ? defaultValue : Value;
}

export function convertToString(value: any, defaultValue: any): any
{
    return isEmpty(value) ? defaultValue : value;
}


/**
 * 해당 uppercount 만큼 현재 경로에서 상위 경로로 갑니다
 * @param uppercount
 */
export function parentPath(uppercount: number) { return upperPath(window.location.pathname, uppercount); }

/**
 * 해당 uppercount 만큼 path 상위 경로로 갑니다
 * @param path
 * @param uppercount
 */
export function upperPath(path: string, uppercount: number)
{
    if (isEmptyString(path)) return path;

    while (uppercount-- > 0)
    {
        const index = path.lastIndexOf('/');

        if (index > 0) path = path.substring(0, index);
        else break;
    }

    return path;
}

function Same(p: string) { return p === '/' || p === '\\'; }
export function PathMaker(A: string | null, B: string | null, PathChar = '/')
{
    A = A ?? '';
    B = B ?? '';

    if (isNullorWhiteSpace(A) || isNullorWhiteSpace(B)) return A + B;
    //example1/ + /example2
    else if (Same(A[A.length - 1]) && Same(B[0])) return A + B.substring(1, B.length);
    else if (Same(B[0]) || Same(A[A.length - 1])) return A + B;
    else return A + PathChar + B;
}
export function PathMakerMulti(...Paths: string[])
{
    let completePath = "";
    Paths.map((Path) => {completePath = PathMaker(completePath, Path); });
    return completePath;
}


export function getFileName(filePath: string|null|undefined): string | null
{
    if(isEmpty(filePath)) return null;

    let index = filePath!.lastIndexOf('/');
    if(index < 0) index = filePath!.lastIndexOf('\\');
    return index > 0 ? filePath!.substring(index + 1) : filePath!;
}


/**
 * 스트립트 로드 (재귀 사용)
 * @param element
 * @param URL
 * @param onComplete
 * @param onError
 */
export function loadExternal(element: Element, URL: string[], onComplete?: () => void, onError?: () => void)
{
    if (element != null)
    {
        const path = URL.splice(0, 1)[0];
        const onLoad = () => {
            if (URL.length > 0) loadExternal(element, URL, onComplete, onError);
            else if (onComplete) onComplete();
        };

        let ref: any;
        if (path!.endsWith(".css")) {
            ref = document.createElement("link");
            ref.href = path!;
            ref.rel = "stylesheet";
        }
        else {
            ref = document.createElement("script");
            ref.src = path!;
            ref.type = "text/javascript";
        }
        ref.onerror = (event: Event) => { console.log(event); if (onError) onError(); }
        ref.onload = onLoad;
        element.append(ref);
    }
}

/**
 * 데이터을 비동기식으로 읽습니다
 * @param data
 */
export const readData = (data: Blob) => new Promise<string | null>((resolve, reject) =>
{
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () =>
    {
        if(reader.result == null) resolve(null);
        else if(typeof reader.result == "string") resolve(reader.result);
        else if(typeof reader.result == "object") resolve(ArrayToBase64(reader.result));
    }
    reader.onerror = (error) => reject(error);
});
/**
 * 파일을 비동기식으로 읽습니다
 * @param file
 */
export const readFile = (file: File) => readData(file);

export const ArrayToBase64 = (data: ArrayLike<number> | ArrayBufferLike) =>
{
    let binary = '';
    const bytes = new Uint8Array(data);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
}

/**
 * Remove the functions from the state. They can't been pushed into the history.
 * https://localcoder.org/uncaught-domexception-failed-to-execute-pushstate-on-history-function
 */
export function cleanStateForHistory(state: any)
{
    const stateWithNoFunctions: any = {};
    for (const key of Object.keys(state)) {
        if (typeof key !== "function") {
            stateWithNoFunctions[key] = state[key];
        }
    }
    return stateWithNoFunctions;
}

/**
 * like this (value || "")
 * @param value
 */
export function toInput(value: any): any { return isEmpty(value) ? "" : value; }

/**
 * 부울 값으로 파싱합니다
 * @param value
 */
export function parseBoolean(value: any)
{
    switch (typeof value)
    {
        case "string": return value.toLowerCase() === "true";
        case "boolean": return value;
        case "number": return value > 0;
        default: return false;
    }
}

/**
 * Know type
 * @param x
 */
/* eslint @typescript-eslint/ban-ts-comment: "off" */
export function getType(x: any): string
{
    if (x == null) {
        return 'null';
    }

    const t = typeof x;
    if (t != "object") {
        return t;
    }

    let c = Object.prototype.toString.apply(x);
    c = c.substring(8, c.length - 1); // [object ?]의 특성을 이용함

    if (c != "Object") {
        return c;
    }

    // @ts-ignore
    if (c.constructor == "Object") {
        return c;
    }
    else {
        let s = x.constructor.toString();
        let i = s.indexOf("(");
        return s.substring(9, i); // function ?( ... 의 특성을 이용함
    }
}

/**
 * FormData to Object (with Array)
 * @param form
 */
/* eslint @typescript-eslint/ban-ts-comment: "off" */
/* eslint prefer-const: "off" */
export function serializeForm(form: FormData): any
{
    // @ts-ignore
    const f = Array.from(form);
    // @ts-ignore
    return  f.reduce((o: any, [k, v]) => {
        let a = v,
            b, i,
            m = k.split('['),
            n = m[0],
            l = m.length;
        if (l > 1) {
            a = b = o[n] || [];
            for (i = 1; i < l; i++) {
                m[i] = (m[i].split(']')[0] || b.length) * 1;
                b = b[m[i]] = ((i + 1) == l) ? v : b[m[i]] || [];
            }
        }
        return { ...o, [n]: a };
    }, {});
}

export function convertObject2FormData(data: any): FormData
{
    let form_data = new FormData();
    for (let key in data )
        form_data.append(key, data[key]);
    return form_data;
}

export function randomKey(postfix?: any): string { return `rndkey_${Math.floor(Math.random() * 9007199254740991)}_${postfix ?? ''}`; }

export type KeyValue = {
    [key: string]: any
}
export function setParam(...params: KeyValue[]): string
{
    const authResult = new URLSearchParams(window.location.search);
    for(let i = 0; i < params.length; i++)
    {
        const key = Object.keys(params[i])[0];
        console.log(params[i])
        authResult.set(key, params[i][key]);
    }
    return authResult.toString();
}

/**
 * https://stackoverflow.com/questions/70689305/customizing-date-serialization-in-axios
 * @param data
 * @param headers
 */
const dateTransformerRequest = (data: any): any =>
{
    if (data instanceof Date) {
        // do your specific formatting here
        return data.toJSON()
    }
    if (Array.isArray(data)) {
        return data.map(dateTransformerRequest)
    }
    if (typeof data === 'object' && data !== null) {
        return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, dateTransformerRequest(value)]))
    }
    return data;
}
/**
 * https://stackoverflow.com/questions/73044657/how-to-cast-parse-axios-rest-response-field-from-string-to-date-within-react
 * @param data
 * @param headers
 */
const dateTransformerResponse = (data: any, headers: AxiosRequestHeaders): any =>
{
    if(headers["Content-Type"] == "application/json")
    {
        console.log(data);
        const dateKeyRx = /date/i;

        return JSON.parse(data, (key, value) =>
            dateKeyRx.test(key) ? new Date(value) : value
        );
    }

    return data;
}
export const axiosHS: AxiosInstance = axios.create({
    //transformRequest: [dateTransformerRequest, ...(axios.defaults.transformRequest as AxiosRequestTransformer[])],
    transformResponse: [dateTransformerResponse, ...(axios.defaults.transformResponse as AxiosResponseTransformer[])]
});

